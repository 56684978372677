export const API_REPROCESS = {
  FILE_NAME: "fileName",
  DATA: "data",
  ROW_NUMBER: "rowNumber",
  DATA_OWNER: "dataOwner",
  BUSINESS_NAME: "businessName",
  PLANT: "plant",
  BATCH_ROUND: "batchRound",
  FILE: "file",
  UPLOAD_SOURCE: "uploadSource",
  RECORD_ID: "recordId",
  PLAN_SERVER: "plantServer",
  SCAN_TRANS_HID: "scanTransHId",
  HH_CD: "hhCd",
  HH_LOCATION_ID: "hhLocationId",
  HH_LOCATION_DESC: "hhLocationDesc",
  SCAN_MODE: "scanMode",
  SCAN_SUB_MODE: "scanSubMode",
  MANUAL_INPUT_FLAG: "manualInputFlag",
  REF_LOCATION_ID: "refLocationId",
  REF_LOCATION_DESC: "refLocationDesc",
  DLV_VHC_ID: "dlvVhcId",
  DLV_INSTR_SHT_NO: "dlvInstrShtNo",
  SHIPPING_INFO_H_ID: "shippingInfoHId",
  TOTAL_QTY: "totalQty",
  DISCREPANCY_QTY: "discrepancyQty",
  STATUS: "sts",
  VANNING_STATUS: "vanningSts",
  SEND_FLAG: "sendFlag",
  H_CREATE_DATE: "hCreateDt",
  H_CREATE_BY: "hCreateBy",
  H_UPDATE_DATE: "hUpdateDt",
  H_UPDATE_BY: "hUpdateBy",
  SCAN_TRANS_DESTINATION_ID: "scanTransDId",
  R_PACKAGE_TYPE_ID: "rPkgTypeId",
  R_PACKAGE_TYPE: "rPkgType",
  R_PACKAGE_TYPE_COMPANY_ABBR: "rPkgOwnerCompAbbr",
  BARCODE_ID: "barcodeId",
  R_PACKAGE_STATUS_FROM: "rPkgStsFrom",
  R_PACKAGE_STATUS_TO: "rPkgStsTo",
  PACK_STATUS_FROM: "packStsFrom",
  PACK_STATUS_TO: "packStsTo",
  SCAN_TIME_STAMP: "scanTimestamp",
  DESTINATION_UPDATE_DATE: "dUpdateDt",
  DESTINATION_UPDATE_BY: "dUpdateBy",
  SCAN_QTY: "scanQty",
  PARKING_FLAG: "parkingFlag",
  REPROCESS_FLAG: "reprocessFlag",
  PROCESS_DATE: "processDt",
  CREATE_BY: "createBy",
};
