import React from "react";
import { Grid, Box } from "@mui/material";
import { DropDown, InputMask } from "components/UI";
import EtDatePickerBar from "pages/Forecast/ShipmentManagementScreen/components/EtDatePickerBar";
// Constant and Type
import { ModeAction } from "state/enum";
import { API_SHIPMENT } from "shared/constants";
export default function MappingFilterBar(props) {
  const {
    mode,
    form,
    setForm,
    dataList: { exporterList, importerList, rPkgList },
  } = props;
  return (
    <Box sx={{ padding: "1rem", mt: 2 }}>
      <Grid container spacing={2} sx={{ mb: 4, alignItems: "center" }}>
        <Grid item xs={2}>
          <strong>*Exporter :</strong>
        </Grid>
        <Grid item xs={3}>
          <DropDown
            data-testid="select_exporter"
            id="select_exporter"
            required={true}
            value={form.exporterId}
            onChange={e => {
              setForm(prev => ({
                ...prev,
                [API_SHIPMENT.EXPORTER_ID]: e.target.value,
              }));
            }}
            menu={exporterList?.map(val => ({
              key: val.impExpCd,
              value: val.impExpCd,
            }))}
            disabled={[ModeAction.ADD, ModeAction.EDIT].includes(mode)}
            placeholder="<Select>"
          />
        </Grid>
        <Grid item xs={1}></Grid>
        <Grid item xs={2}>
          <strong>*Importer :</strong>
        </Grid>
        <Grid item xs={3}>
          <DropDown
            data-testid="select_importer"
            id="select_importer"
            required={true}
            value={form.importerId}
            onChange={e => {
              setForm(prev => ({
                ...prev,
                [API_SHIPMENT.IMPORTER_ID]: e.target.value,
              }));
            }}
            menu={importerList?.map(val => ({
              key: val.companyId,
              value: val.companyAbbr,
            }))}
            disabled={[ModeAction.ADD, ModeAction.EDIT].includes(mode)}
            placeholder="<Select>"
          />
        </Grid>

        <Grid item xs={2}>
          <strong>*Forecast Month :</strong>
        </Grid>
        <Grid item xs={2}>
          <InputMask
            id="input_forecast_period"
            mask="mM/YYYY"
            fullWidth={true}
            formatChars={{
              Y: "[0-9]",
              m: "[0-1]",
              M: "[0-9]",
            }}
            maskChar={null}
            alwaysShowMask={false}
            value={form.forecastMonth}
            onChange={e =>
              setForm(prev => ({
                ...prev,
                [API_SHIPMENT.FORECAST_MONTH]: e.target.value,
              }))
            }
            required={true}
            disabled={[ModeAction.ADD, ModeAction.EDIT].includes(mode)}
          />
        </Grid>
        <Grid item xs={2}>
          <strong>MM/YYYY</strong>
        </Grid>
        <Grid item xs={2}>
          <strong>*R-Package Owner :</strong>
        </Grid>
        <Grid item xs={3}>
          <DropDown
            required={true}
            id="select_r_pkg_compabbr"
            value={form.rPkgOwnerId}
            onChange={e => {
              setForm(prev => ({
                ...prev,
                [API_SHIPMENT.R_RKG_OWNER_ID]: e.target.value,
              }));
            }}
            menu={rPkgList?.map(val => ({
              key: val.rPkgOwnerCompId,
              value: val.rPkgOwnerCompAbbr,
            }))}
            disabled={[ModeAction.ADD, ModeAction.EDIT].includes(mode)}
            placeholder="<Select>"
          />
        </Grid>
      </Grid>
      <EtDatePickerBar form={form} setForm={setForm} onEta={true} />
    </Box>
  );
}
