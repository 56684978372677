import { userProfile } from "constant";
import { saveAs } from "utils/download-file";
import useMutation from "hooks/useMutation";
import useQuery from "hooks/useQuery";
import { useMutateData, useMutateDownload } from "shared/services/base.service";
const BaseServicePath = process.env.REACT_APP_API_PREFIX;
export const useCategory = () => {
  return useQuery({
    url: "/category/COMMON/R_PKG_GROUP/ASC",
    method: "GET",
    enabled: true,
    select: data => data.result,
    keepPreviousData: true,
  });
};

export const useGetType = ({ dataOwner, rPkgOwnerCompAbbr, cd }) => {
  return useQuery({
    url: `/r-package/type/group/${dataOwner}/${rPkgOwnerCompAbbr}/${cd}`,
    method: "GET",
    enabled: cd !== "",
    select: data => data.result,
  });
};

export const useGetMaker = ({ dataOwner, cd }) => {
  return useQuery({
    // url: `/r-package/maker/${dataOwner}/${cd}`,
    url: `/maker/${dataOwner}/${cd}`,
    method: "GET",
    enabled: !!cd,
    select: data => data.result,
  });
};
export const useGetMakerAll = ({ dataOwner, cd }) => {
  return useQuery({
    url: `/maker/all/${dataOwner}/${cd}`,
    method: "GET",
    enabled: !!cd,
    select: data => data.result,
  });
};

export const useGetLocation = ({ dataOwner }) => {
  return useQuery({
    url: `/r-module/route-path/${dataOwner}`,
    method: "GET",
    enabled: dataOwner !== "",
    select: data => data.result,
  });
};

export const useSearchModule = ({
  rPkgTypeId,
  plantId,
  poNo,
  barcodeId,
  locationId,
  firstReceiveDtFrom,
  firstReceiveDtTo,
  pageNumber,
  rowsPerPage,
  dataOwner,
}) => {
  return useMutation({
    url: "/r-module/search",
    method: "POST",
    data: {
      rPkgTypeId,
      plantId,
      poNo,
      barcodeId,
      locationId,
      firstReceiveDtFrom,
      firstReceiveDtTo,
      pageNumber,
      rowsPerPage,
      dataOwner: dataOwner,
    },
  });
};

export const useGetRpagkageInTable = ({
  dataOwner = userProfile.dataOwner,
  rPkgOwnerCompAbbr = userProfile.packageOwner,
  cd,
}) => {
  return useQuery({
    url: `/r-package/${dataOwner}/${rPkgOwnerCompAbbr}/${cd}`,
    method: "GET",
    enabled: true,
    select: data => data.result,
    keepPreviousData: true,
  });
};

export const useSearchOtherMaterials = ({
  dataOwner,
  rPkgTypeId,
  makerPlantId,
  poNo,
  firstReceiveDtFrom,
  firstReceiveDtTo,
  pageNumber,
  rowsPerPage,
  // "dataOwner": "TMATH",
  // "rPkgTypeId": "PKT_1208_000004",
  // "makerPlantId": "PLT_1208_000028",
  // "poNo": "BKF120101",
  // "firstReceiveDtFrom": "28/03/2023",
  // "firstReceiveDtTo": "28/03/2023",
  // "pageNumber": 1,
  // "rowsPerPage": 50
}) => {
  return useMutation({
    url: "/other-materials/search",
    method: "POST",
    data: {
      dataOwner,
      rPkgTypeId,
      makerPlantId,
      poNo,
      firstReceiveDtFrom,
      firstReceiveDtTo,
      pageNumber,
      rowsPerPage,
    },
  });
};
export const useRPkgMasterOtherDownloadExcelMutation = () => {
  const { mutate } = useMutateDownload({
    invalidateKeys: [],
  });
  return async (body) => {
    return await mutate(
      {
        url: `${BaseServicePath}/other-materials/download/excel`,
        method: "POST",
        data: body,
        responseType: "arraybuffer",
      },

      {
        onSuccess: response => {
          saveAs(response);
          // const blob = new Blob([data], {
          //   type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          // });
          // (blob, `${fileName}`);
        },
      }
    );
  };
};
export const useRPkgMasterRModuleDownloadExcelMutation = () => {
  const { mutate } = useMutateDownload({
    invalidateKeys: [],
  });
  return async (body) => {
    return await mutate(
      {
        url: `${BaseServicePath}/r-module/download/excel`,
        method: "POST",
        data: body,
        responseType: "arraybuffer",
      },
      {
        onSuccess: response => {
          saveAs(response);
          // const blob = new Blob([data], {
          //   type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          // });
          // (blob, `${fileName}`);
        },
      }
    );
  };
};
