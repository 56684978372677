import { Grid } from "@mui/material";
import { DropDown, Typography } from "components/UI";
import React, { Fragment } from "react";
import { ModeAction } from "state/enum";
import { findObject } from "utils";

export default function CriterialSection(props) {
  const {
    mode,
    form,
    setForm,
    handleClearCriterial,
    dataList: { rPackageOwnerList, rPackageTypeList, wareHouseList },
  } = props;

  return (
    <Fragment>
      <Grid container spacing={1} sx={{ mb: 2, alignItems: "center" }}>
        <Grid item xs={2}>
          <strong>*R-Package Owner:</strong>
        </Grid>
        <Grid item xs={3}>
          <DropDown
            id="select-rPkg-owner"
            required
            value={form?.rPackageOwnerId}
            menu={rPackageOwnerList?.map(val => ({
              key: val?.rPkgOwnerCompId,
              value: val?.rPkgOwnerCompAbbr,
            }))}
            onChange={event => {
              setForm(prev => ({
                ...prev,
                rPackageOwnerId: event.target.value,
              }));
              handleClearCriterial();
            }}
            autoFocus={true}
            disabled={[ModeAction.EDIT].includes(mode)}
            defaultValue=""
            placeholder="<Select>"
          />
        </Grid>
      </Grid>
      {/* ============================================== */}
      <Grid container spacing={1} sx={{ mb: 2, alignItems: "center" }}>
        <Grid item xs={2}>
          <Typography
            id="label-ware-house"
            content="WareHouse:"
            sx={{
              color: "#000",
              fontFamily: "Kanit",
              fontWeight: "bold",
              mr: 2,
            }}
            noWrap={false}
          />
        </Grid>
        <Grid item xs={3}>
          <DropDown
            id="select-ware-house"
            value={form.wareHouseId}
            menu={wareHouseList?.map(val => ({
              key: val.plantId,
              value: `${val.companyAbbr}:${val.impExpCd}`,
            }))}
            onChange={event => {
              setForm(prev => ({
                ...prev,
                rPackageTypeId: "",
                wareHouseId: event.target.value,
              }));
              handleClearCriterial();
            }}
            disabled={[ModeAction.EDIT].includes(mode)}
            defaultValue=""
            placeholder="<All>"
          />
        </Grid>
        <Grid item xs={1}></Grid>
        <Grid item xs={2}>
          <Typography
            id="label-rPkg-type"
            content="R-Package Type:"
            sx={{
              color: "#000",
              fontFamily: "Kanit",
              fontWeight: "bold",
              mr: 2,
            }}
            noWrap={false}
          />
        </Grid>
        <Grid item xs={3}>
          <DropDown
            id="select-rPkg-type"
            value={form?.rPackageTypeId}
            menu={rPackageTypeList?.map(val => ({
              key: val?.rPkgTypeId,
              value: val?.rPkgType,
            }))}
            onChange={event => {
              setForm(prev => ({
                ...prev,
                rPackageTypeId: event.target.value,
                rPkgType: findObject({
                  data: rPackageTypeList,
                  value: event.target.value,
                  property: "rPkgTypeId",
                  field: "rPkgType",
                }),
              }));
              handleClearCriterial();
            }}
            disabled={[ModeAction.EDIT].includes(mode)}
            defaultValue=""
            placeholder="<All>"
          />
        </Grid>
      </Grid>
    </Fragment>
  );
}
