import React, { Fragment, useCallback } from "react";
//utils
import { validationSearchForm } from "utils/validation";
import { findObject, responseErrors } from "utils";
import sleep from "shared/hooks/Sleep.js";
// Share Component
import { DataGridTable, SubmitBar } from "components/UI";
// Service
import { useApmcCreateMutation, useApmcUpdateMutation } from "shared/services/inventory-forecast";
// Type
import { API_NAME_APMC, PAGINATION, ROW_PER_PAGE } from "shared/constants";
import { ModeAction, MessageType } from "state/enum";
import { userProfile } from "constant";
import { GENERATE_STATUS, UPLOAD_STATUS } from "../constants/apmc.type.js";
import { getFunctionIDTemplate } from "../hooks/utils.js";
import httpStatus from "http-status";
export default function Table({
  form,
  onSearch,
  setOnSearch,
  mode,
  setMode,
  rows,
  setRows,
  columns,
  getSearch,
  loading: { startLoading, stopLoading },
  dataList: { impExpList, dataTypeList, modeList },
  setMsg: { setMsgAlert, setMsgError },
  pagination: { pagination, pageNumber, setPagination, setPageNumber },
  rowSelection: { rowSelectionModel, setRowSelectionModel, rowModesModel, setRowModesModel },
  rest,
}) {
  const apmcCreate = useApmcCreateMutation();
  const apmcEdit = useApmcUpdateMutation();
  const handleChangePagination = async (event, value) => {
    try {
      setPageNumber(value);

      const bodySearch = {
        [API_NAME_APMC.DATA_OWNER]: userProfile.dataOwner,
        [API_NAME_APMC.COMPANY_ABBR]: userProfile.dataOwner,
        [API_NAME_APMC.FORECAST_MONTH]: form?.forecastMonth,
        [API_NAME_APMC.MODE_CD]: form.mode,
        [API_NAME_APMC.DATA_TYPE_CD]: form?.dataTypeCd,
        [API_NAME_APMC.UPLOAD_STATUS_CD]: form?.uploadStatusCd,
        [PAGINATION.PAGE_NUMBER]: value,
        [PAGINATION.ROW_PER_PAGE]: ROW_PER_PAGE,
      };
      startLoading();
      const { searchData, data } = await getSearch(bodySearch, value, ROW_PER_PAGE);
      stopLoading();

      setPagination(searchData?.result?.pagination ?? {});
      setPageNumber(searchData?.result?.pagination?.pageNumber);
      setRows(data);
    } catch (error) {
      stopLoading();
      const errors = responseErrors(error);
      setMsgError([`${errors}`]);
      window.scrollTo(0, 0);
      return;
    }
  };
  const handleCreate = async newRow => {
    try {
      const body = {
        [API_NAME_APMC.DATA_OWNER]: form.dataOwner, // !MOCK
        [API_NAME_APMC.CREATE_BY]: form?.userId, // !MOCK
        [API_NAME_APMC.IMP_EXP_CD]: newRow?.uploadImpExpCd,
        [API_NAME_APMC.MODE]: newRow?.mode,
        [API_NAME_APMC.FORECAST_MONTH]: newRow?.forecastMonth,
        [API_NAME_APMC.DATA_TYPE_CD]: newRow?.dataType,
        [API_NAME_APMC.DATA_TYPE]: newRow?.dataType
          ? findObject({
              data: dataTypeList,
              value: newRow?.dataType,
              property: "cd",
              field: "value",
            })
          : "",
        [API_NAME_APMC.ORG_FILE_NAME]: newRow?.orgFileName,
        // [API_NAME_APMC.FUNCTION_ID]: WEB_FUNCTION_ID.FORECAST_APMC,
        [API_NAME_APMC.FUNCTION_ID]: getFunctionIDTemplate(newRow.dataType),
        [API_NAME_APMC.FILE]: form?.file,
      };
      const { isSuccess, errors } = validationSearchForm({
        data: body,
        rule: [
          {
            field: API_NAME_APMC.IMP_EXP_CD,
            type: MessageType.EMPTY,
            key: ["Importer/ Exporter"],
          },
          {
            field: API_NAME_APMC.MODE,
            type: MessageType.EMPTY,
            key: ["Mode"],
          },
          {
            field: API_NAME_APMC.DATA_TYPE_CD,
            type: MessageType.EMPTY,
            key: ["Data Type"],
          },
          {
            field: API_NAME_APMC.ORG_FILE_NAME,
            type: MessageType.EMPTY,
            key: ["File Name"],
          },
          {
            field: API_NAME_APMC.FORECAST_MONTH,
            type: MessageType.MONTH_INVALID_50,
            key: ["Forecast Month", "MM/YYYY"],
          },
        ],
      });
      if (!isSuccess) {
        setMsgError(errors);
        window.scrollTo(0, 0);
        return false;
      }
      const createData = new FormData();
      createData.append([API_NAME_APMC.DATA_OWNER], body.dataOwner);
      createData.append([API_NAME_APMC.IMP_EXP_CD], body.impExpCd);
      createData.append([API_NAME_APMC.MODE], body.mode);
      createData.append([API_NAME_APMC.FORECAST_MONTH], body.forecastMonth);
      createData.append([API_NAME_APMC.DATA_TYPE_CD], body.dataTypeCd);
      createData.append([API_NAME_APMC.DATA_TYPE], body.dataType);
      createData.append([API_NAME_APMC.CREATE_BY], body.createBy);
      createData.append([API_NAME_APMC.FUNCTION_ID], body.functionId);
      createData.append("file", body.file);
      const response = await apmcCreate(createData);
      if (response?.statusCode && response?.statusCode === httpStatus.BAD_REQUEST) {
        const errors = response?.errors.map(val => {
          return `${val?.code}: ${val?.message}\n`;
        });
        setMsgError(errors);
      } else {
        const msg = response?.message;
        setMsgAlert([msg]);
      }
      window.scrollTo(0, 0);
      return true;
    } catch (error) {
      stopLoading();
      const errors = responseErrors(error);
      setMsgError(errors);
      window.scrollTo(0, 0);
      return false;
    }
  };
  const handleUpdate = async row => {
    try {
      const fileUploadId = row.fileUploadId;
      const functionId = getFunctionIDTemplate(
        findObject({
          data: dataTypeList,
          value: row?.dataType,
          property: "value",
          field: "cd",
        })
      );
      const body = {
        [API_NAME_APMC.DATA_OWNER]: form.dataOwner, // !MOCK
        [API_NAME_APMC.IMP_EXP_CD]: row?.uploadImpExpCd,
        [API_NAME_APMC.MODE]: row?.mode,
        [API_NAME_APMC.FORECAST_MONTH]: row?.forecastMonth,
        [API_NAME_APMC.DATA_TYPE_CD]: row?.dataType
          ? findObject({
              data: dataTypeList,
              value: row?.dataType,
              property: "value",
              field: "cd",
            })
          : "",
        [API_NAME_APMC.DATA_TYPE]: row?.dataType,
        [API_NAME_APMC.UPDATE_BY]: form.userId, // !MOCK
        [API_NAME_APMC.ORG_FILE_NAME]: form?.orgFileName,
        [API_NAME_APMC.FUNCTION_ID]: functionId,
        [API_NAME_APMC.FILE]: form?.file,
      };
      const { isSuccess, errors } = validationSearchForm({
        data: body,
        rule: [
          {
            field: API_NAME_APMC.ORG_FILE_NAME,
            type: MessageType.EMPTY,
            key: ["File Name"],
          },
        ],
      });
      if (!isSuccess) {
        setMsgError(errors);
        window.scrollTo(0, 0);
        return false;
      }
      const updateData = new FormData();

      updateData.append([API_NAME_APMC.DATA_OWNER], body.dataOwner); //!MOCK
      updateData.append([API_NAME_APMC.IMP_EXP_CD], body.impExpCd);
      updateData.append([API_NAME_APMC.MODE], body.mode);
      updateData.append([API_NAME_APMC.FORECAST_MONTH], body.forecastMonth);
      updateData.append([API_NAME_APMC.DATA_TYPE_CD], body.dataTypeCd);
      updateData.append([API_NAME_APMC.DATA_TYPE], body.dataType);
      updateData.append([API_NAME_APMC.UPDATE_BY], body.updateBy);
      updateData.append([API_NAME_APMC.FUNCTION_ID], body.functionId);
      updateData.append([API_NAME_APMC.FILE], body.file);
      const response = await apmcEdit(fileUploadId, updateData);

      const msg = response.message;
      setMsgAlert([msg]);
      window.scrollTo(0, 0);
      return true;
    } catch (error) {
      stopLoading();
      const errors = responseErrors(error);
      setMsgError(errors);
      stopLoading();
      window.scrollTo(0, 0);
      return false;
    }
  };

  const handleProcessRowUpdateError = useCallback(error => {
    console.error(` children:${error.message}, severity: ${error}`);
  }, []);
  const processRowUpdate = async newRow => {
    let response;
    let updatedRow;
    try {
      setMsgError([]);
      setMsgAlert([]);
      startLoading();
      if (newRow.isNew) {
        response = await handleCreate(newRow);
        updatedRow = { ...newRow, isNew: false };
      } else {
        response = await handleUpdate(newRow);
        updatedRow = { ...newRow };
      }
      // throw to onProcessRowUpdate is error
      if (!response) {
        stopLoading();
        return false;
      }
      console.log(rows.length === 1 && ModeAction.ADD === mode, rows);

      if (rows.length === 1 && ModeAction.ADD === mode) {
        setMode(ModeAction.VIEW);
        setOnSearch(false);
        setRowModesModel({});
        setRowSelectionModel([]);
        setRows([]);
        stopLoading();
      } else {
        setMode(ModeAction.VIEW);
        setRowSelectionModel([]);
        // setRowModesModel({});
        await sleep(5000);
        const body = {
          [API_NAME_APMC.DATA_OWNER]: userProfile.dataOwner,
          [API_NAME_APMC.COMPANY_ABBR]: userProfile.dataOwner,
          [API_NAME_APMC.MODE_CD]: !form?.mode
            ? ""
            : findObject({
                data: modeList,
                value: form?.mode,
                property: "cd",
                field: "value",
              }),
          [API_NAME_APMC.FORECAST_MONTH]: form.forecastMonth,
          [API_NAME_APMC.DATA_TYPE_CD]: form.dataTypeCd,
          [API_NAME_APMC.UPLOAD_STATUS_CD]: form.uploadStatusCd,
          [PAGINATION.PAGE_NUMBER]: pageNumber,
          [PAGINATION.ROW_PER_PAGE]: ROW_PER_PAGE,
        };
        const { searchData, data } = await getSearch(body);
        stopLoading();
        setPagination(searchData?.result?.pagination ?? {});
        setPageNumber(searchData?.result?.pagination?.pageNumber);
        setRows(data);
      }
      stopLoading();
      return updatedRow;
    } catch (error) {
      stopLoading();
      const errors = responseErrors(error);
      setMsgError(errors);
      window.scrollTo(0, 0);
      return false;
    }
  };
  const handleRowDisableSelect = params => {
    if (params?.row.useSts === GENERATE_STATUS.GENERATED) return false;
    if (params?.row.uploadSts !== UPLOAD_STATUS.PROCESSING) {
      return true;
    }
  };
  return (
    <Fragment>
      {onSearch && (
        <DataGridTable
          mode={mode}
          onSearch={onSearch}
          setMode={setMode}
          rows={rows}
          isMultipleSelection={false}
          checkboxSelection={true}
          onCellClick={false}
          rowHeight={45}
          processRowUpdate={processRowUpdate}
          onProcessRowUpdateError={handleProcessRowUpdateError}
          handleRowDisableSelect={handleRowDisableSelect}
          rowSelection={{
            rowModesModel: rowModesModel,
            setRowModesModel: setRowModesModel,
            rowSelectionModel: rowSelectionModel,
            setRowSelectionModel: setRowSelectionModel,
          }}
          pagination={{
            pagination: pagination,
            pageNumber: pageNumber,
            handleChangePagination: handleChangePagination,
          }}
          column={{
            columns: columns,
            columnGroupingModel: null,
            columnVisibilityModel: {
              [API_NAME_APMC.FILE_UPLOAD_ID]: false,
              [API_NAME_APMC.FILE_NAME]: false,
              [API_NAME_APMC.DATA_OWNER]: false,
              [API_NAME_APMC.UPDATE_DATE]: false,
              [API_NAME_APMC.MODULE_ID]: false,
              [API_NAME_APMC.FUNCTION_ID]: false,
              [API_NAME_APMC.D_HODTCRE]: false,
            },
          }}
        />
      )}
      <SubmitBar
        setOnSearch={setOnSearch}
        mode={mode}
        rows={rows}
        setMode={setMode}
        setRows={setRows}
        setRowModesModel={setRowModesModel}
        setMsgError={setMsgError}
        rowModesModel={rowModesModel}
        rowSelectionModel={rowSelectionModel}
        setRowSelectionModel={setRowSelectionModel}
        functionId={"WDN92020"}
      />
    </Fragment>
  );
}
