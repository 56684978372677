import React, { Fragment } from "react";
// import "dayjs/locale/en";
// Components
import { Grid } from "@mui/material";
import { DropDown, InputTextField, DatePickerField } from "components/UI";
import { API_LOG_MONITORING } from "shared/constants/api-name/common/logMonitoring";

export default function CriteriaBar(props) {
  const {
    searchForm,
    setSearchForm,
    dataList: { moduleData, functionData, logStatusDetailData, levelData },
  } = props;
  return (
    <Fragment>
      <Grid container spacing={2} sx={{ mt: 4, alignItems: "center" }}>
        <Grid item flex="1">
          <strong>Module : </strong>
        </Grid>
        <Grid item flex="3">
          <DropDown
            id="select_module"
            value={searchForm?.moduleId}
            onChange={e =>
              setSearchForm(prev => ({
                ...prev,
                [API_LOG_MONITORING.MODULE_ID]: e.target.value,
              }))
            }
            menu={moduleData?.map(val => ({
              key: val.moduleId,
              value: val.moduleName,
            }))}
            placeholder="<All>"
            defaultValue=""
            autoFocus={true}
          />
        </Grid>
        <Grid item flex="1">
          <strong>Function : </strong>
        </Grid>
        <Grid item flex="3">
          <DropDown
            id="select_function"
            value={searchForm?.functionId}
            onChange={e =>
              setSearchForm(prev => ({
                ...prev,
                [API_LOG_MONITORING.FUNCTION_ID]: e.target.value,
              }))
            }
            menu={functionData?.map(val => ({
              key: val.funtionId,
              value: val.funtionName,
            }))}
            placeholder="<All>"
            defaultValue=""
          />
        </Grid>
        <Grid item />
        <Grid item flex="1">
          <strong>Status : </strong>
        </Grid>
        <Grid item flex="2">
          <DropDown
            id="select_status"
            value={searchForm?.statusId}
            onChange={e =>
              setSearchForm(prev => ({
                ...prev,
                [API_LOG_MONITORING.STATUS_ID]: e.target.value,
              }))
            }
            menu={logStatusDetailData?.map(val => ({
              key: val.cd,
              value: val.value,
            }))}
            placeholder="<All>"
            defaultValue=""
          />
        </Grid>
      </Grid>
      <Grid container spacing={2} sx={{ mt: 1, alignItems: "center" }}>
        {/* ======================================================== */}
        {/* ======================================================== */}
        <Grid item flex="1">
          <strong style={{ fontSize: 14 }}>User ID : </strong>
        </Grid>
        <Grid item flex="2">
          <InputTextField
            id="input_userId"
            fullWidth={true}
            value={searchForm?.userId}
            onChange={e =>
              setSearchForm(prev => ({
                ...prev,
                [API_LOG_MONITORING.USER_ID]: e.target.value,
              }))
            }
          />
        </Grid>
        <Grid item flex="1">
          <strong style={{ fontSize: 14 }}>App ID : </strong>
        </Grid>
        <Grid item flex="2">
          <InputTextField
            id="input_appId"
            fullWidth={true}
            value={searchForm?.appId}
            onChange={e =>
              setSearchForm(prev => ({
                ...prev,
                [API_LOG_MONITORING.APP_ID]: e.target.value.trim(),
              }))
            }
          />
        </Grid>
        <Grid item flex="1">
          <strong>From : </strong>
        </Grid>
        <Grid item flex="3">
          <DatePickerField
            id="date-from"
            value={searchForm?.dateFrom}
            fullWidth={true}
            onChange={date => {
              setSearchForm(prev => ({
                ...prev,
                [API_LOG_MONITORING.DATE_FORM]: date,
              }));
            }}
          />
        </Grid>
        <Grid item flex="1">
          <strong>To : </strong>
        </Grid>
        <Grid item flex="3">
          <DatePickerField
            id="date-to"
            fullWidth={true}
            onChange={date =>
              setSearchForm(prev => ({
                ...prev,
                [API_LOG_MONITORING.DATE_TO]: date,
              }))
            }
            value={searchForm?.dateTo}
          />
        </Grid>
        <Grid item flex="1">
          <strong>Level : </strong>
        </Grid>
        <Grid item flex="2">
          <DropDown
            id="select_level"
            value={searchForm?.levelId}
            onChange={e =>
              setSearchForm(prev => ({
                ...prev,
                [API_LOG_MONITORING.LEVEL_ID]: e.target.value,
              }))
            }
            menu={levelData?.map(val => ({
              key: val.statusId,
              value: val.status,
            }))}
            placeholder="<All>"
            defaultValue=""
          />
        </Grid>
      </Grid>
    </Fragment>
  );
}
