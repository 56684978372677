import React, { Fragment } from "react";
import { Grid } from "@mui/material";
import { DatePickerField } from "components/UI";
import { API_SHIPMENT } from "shared/constants";
export default function EtDatePickerBar(props) {
  const { form, setForm, onEta = false } = props;

  return (
    <>
      <Grid container spacing={2} sx={{ mb: 2 }}>
        <Grid item xs={2} sx={{ mt: "8px" }}>
          <strong>ETD From :</strong>
        </Grid>
        <Grid item xs={onEta ? 3 : 2}>
          <DatePickerField
            fullWidth={true}
            id="input_etd_from"
            value={form?.etdFrom}
            onChange={newValue => {
              setForm(prev => ({
                ...prev,
                [API_SHIPMENT.ETD_FORM]: newValue,
              }));
            }}
          />
        </Grid>

        {onEta && <Grid item xs={1}></Grid>}
        <Grid item xs={onEta ? 2 : 1} sx={{ mt: "8px" }}>
          <strong>ETD To :</strong>
        </Grid>
        <Grid item xs={onEta ? 3 : 2}>
          <DatePickerField
            id="input_etd_to"
            fullWidth={true}
            value={form?.etdTo}
            onChange={newValue => {
              setForm(prev => ({
                ...prev,
                [API_SHIPMENT.ETD_TO]: newValue,
              }));
            }}
          />
        </Grid>
        <Grid item xs={1}></Grid>
      </Grid>
      <Grid container spacing={2} sx={{ mb: 2 }}>
        {onEta && (
          <>
            <Grid item xs={2} sx={{ mt: "8px" }}>
              <strong>ETA From :</strong>
            </Grid>
            <Grid item xs={3}>
              <DatePickerField
                id="input_eta_from"
                fullWidth={true}
                value={form?.etaFrom}
                onChange={newValue => {
                  setForm(prev => ({
                    ...prev,
                    [API_SHIPMENT.ETA_FORM]: newValue,
                  }));
                }}
              />
            </Grid>
            <Grid item xs={1}></Grid>
            <Grid item xs={2} sx={{ mt: "8px" }}>
              <strong>ETA To :</strong>
            </Grid>
            <Grid item xs={3}>
              <DatePickerField
                id="input_eta_to"
                fullWidth={true}
                value={form?.etaTo}
                onChange={newValue => {
                  setForm(prev => ({
                    ...prev,
                    [API_SHIPMENT.ETA_TO]: newValue,
                  }));
                }}
              />
            </Grid>
          </>
        )}
      </Grid>
    </>
  );
}
