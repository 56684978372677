import React from "react";
import MenuItem from "@mui/material/MenuItem";
import { Select, FormControl, InputLabel, styled } from "@mui/material";
import InputBase from "@mui/material/InputBase";

const StyledSelectField = styled(InputBase)(({ theme, error }) => ({
  "& .MuiInputBase-input": {
    // borderRadius: 4,
    // position: "relative",
    // backgroundColor: theme.palette.background.paper,
    border: `1.5px solid ${error ? theme.palette.primary.main : theme.palette.secondary.medium}`,
    // fontSize: 16,
    padding: "5px 0px 5px 12px",
    // transition: theme.transitions.create(["border-color", "box-shadow"]),
    "&:focus": {
      border: `1.5px solid black !important`,
      borderRadius: ".3rem",
      color: "#000",
    },
  },
}));

const InputDropDown = ({
  id,
  value,
  name = "",
  memu,
  onChange,
  disabled = false,
  bgColor = "white",
  className = "",
  required,
  placeholder = "<Select>",
  defaultValue = "",
  focus = false,
  rest,
}) => {
  return (
    <FormControl style={{ width: "100%" }}>
      <InputLabel
        id="test-select-label"
        shrink={false}
        size="small"
        variant="outlined"
        style={{
          top: "50%",
          left: "5%",
          transform: "translateY(-50%)",
        }}>
        {!memu ? placeholder : memu && !memu?.map(item => item.key).includes(value) && placeholder}
      </InputLabel>
      <Select
        {...rest}
        data-testid={id}
        id={id}
        name={name}
        sx={{ minWidth: "100%", background: bgColor }}
        size="small"
        value={value}
        label=""
        onChange={onChange}
        disabled={disabled}
        className={className}
        required={required}
        style={{ height: "32px" }}
        placeholder={placeholder}
        autoFocus={focus}
        // renderValue={(selected) => {
        //   if (selected.length === 0) {
        //     return <span>{placeholder}</span>;
        //   }

        //   return selected;
        // }}
        input={<StyledSelectField />}>
        <MenuItem value={defaultValue}>
          <span>{placeholder}</span>
        </MenuItem>
        {memu &&
          memu.map((val, index) => (
            <MenuItem key={index} value={val.key}>
              {val.value}
            </MenuItem>
          ))}
      </Select>
    </FormControl>
  );
};

export default InputDropDown;
