import React, { Fragment, useState, useMemo, useEffect } from "react";
// Utils
import { isUndefined } from "lodash";
import { responseErrors } from "utils";
import { validationUploadFile } from "utils/validation";
// SHARE COMPONENT
import { HeaderContentBar } from "components/Layout";
import { AlertMessage } from "components/UI";
import { Box, Grid } from "@mui/material";
// Hook
import { useColumn } from "./hooks/useColumn";
import useLocalStorage from "shared/hooks/useLocalStorage";
// COMPONENT
import FilterBar from "./components/FilterBar";
import ButtonBar from "./components/ButtonBar";
import Table from "./components/Table";
// API
import {
  useApmcSearchMutation,
  useGetForecastModeAscQuery,
  useGetApmcForecastDataTypeQuery,
  useGetForecastUploadStatusQuery,
  useApmcImporterExporterQuery,
} from "shared/services/inventory-forecast";
//TYPE
import { ModeAction, MessageType } from "state/enum";
import { API_NAME_APMC, DEFAULT_LOCAL_STATE, ROW_PER_PAGE } from "shared/constants";
import { userProfile } from "constant";
import { useLoadingContext } from "shared/contexts/LoadingContext";
import { transformDataApmc } from "./hooks/transfromData";
import { GENERATE_STATUS } from "./constants/apmc.type";

const DEFAULT_VALUE = {
  [API_NAME_APMC.DATA_OWNER]: userProfile.dataOwner,
  [API_NAME_APMC.COMPANY_ABBR]: userProfile.dataOwner,
  [API_NAME_APMC.FORECAST_MONTH]: "",
  [API_NAME_APMC.MODE]: "",
  [API_NAME_APMC.DATA_TYPE_CD]: "",
  [API_NAME_APMC.UPLOAD_STATUS_CD]: "",
  [API_NAME_APMC.ORG_FILE_NAME]: "",
  [API_NAME_APMC.FILE]: {},
  [API_NAME_APMC.IMP_EXP_CD]: "",
  [API_NAME_APMC.USER_ID]: userProfile.userId,
};
export default function ApmcForecastScreen() {
  const { startLoading, stopLoading } = useLoadingContext();
  const [logDetail, setLogDetail] = useLocalStorage("logDetail", DEFAULT_LOCAL_STATE);
  // API
  const apmcSearch = useApmcSearchMutation();

  // Form
  const [form, setForm] = useState(DEFAULT_VALUE);
  const [msgError, setMsgError] = useState([]);
  const [msgAlert, setMsgAlert] = useState([]);
  const [onSearch, setOnSearch] = useState(false);
  const [mode, setMode] = useState(ModeAction.VIEW);

  // ? Table

  const [rows, setRows] = useState([]);
  const [rowModesModel, setRowModesModel] = useState({});
  const [rowSelectionModel, setRowSelectionModel] = useState([]);
  const [pagination, setPagination] = useState({});
  const [pageNumber, setPageNumber] = useState(1);
  // API
  const { data: modeList } = useGetForecastModeAscQuery();
  const { data: dataTypeList } = useGetApmcForecastDataTypeQuery();
  const { data: uploadStatusList } = useGetForecastUploadStatusQuery();
  const { data: impExpList } = useApmcImporterExporterQuery();
  const [disable, setDisable] = useState(false);

  // ? Upload File
  const handleUploadFile = async (field, file, maxLength) => {
    try {
      setMsgError([]);
      setMsgAlert([]);
      if (isUndefined(file)) {
        setForm(prev => ({
          ...prev,
          file: {},
          [API_NAME_APMC.ORG_FILE_NAME]: "",
        }));
        return true;
      }
      // validate
      const { isSuccess, errors } = validationUploadFile({
        file: file,
        rule: [
          {
            type: MessageType.TYPE_EXCEL,
            key: [file.name, "excel"],
          },
          {
            type: MessageType.FILE_NAME,
            key: [`Uploaded File Name`, maxLength],
          },
        ],
      });
      if (!isSuccess) {
        setMsgError(errors);
        window.scrollTo(0, 0);
        return;
      }
      setForm(prev => ({
        ...prev,
        file: file,
        [API_NAME_APMC.ORG_FILE_NAME]: file.name,
      }));
      return true;
    } catch (error) {
      const errors = responseErrors(error);
      setMsgError(errors);
      return;
    }
  };
  const handleLinkStatus = async (value, event) => {
    try {
      event.preventDefault();
      let apmcState;
      if ([GENERATE_STATUS.GENERATED, GENERATE_STATUS.ERROR].includes(value?.useSts)) {
        apmcState = {
          dataOwner: userProfile.dataOwner,
          moduleId: value?.moduleId,
          functionId: value?.functionId,
          statusId: "",
          userId: "",
          appId: "",
          dateFrom: value?.useDt,
          dateTo: value?.useDt,
          levelId: "",
        };
      } else {
        apmcState = {
          dataOwner: userProfile.dataOwner,
          moduleId: value?.moduleId,
          functionId: value?.functionId,
          statusId: "",
          userId: value?.uploadBy,
          appId: value?.aplId,
          dateFrom: value?.uploadDt,
          dateTo: value?.uploadDt,
          levelId: "",
        };
      }

      setLogDetail(apmcState);
      window.open("/common/monitoring/log-monitoring-detail", "_blank");
    } catch (error) {
      const errors = responseErrors(error);
      setMsgError([`${errors}`]);
      return;
    }
  };

  const columns = useMemo(
    () =>
      // eslint-disable-next-line react-hooks/rules-of-hooks
      useColumn({
        rows,
        mode,
        dataList: {
          modeList: modeList,
          dataTypeList: dataTypeList,
          uploadStatusList: uploadStatusList,
          impExpList: impExpList,
        },
        setDisable,
        disable,
        handleUploadFile,
        handleLinkStatus,
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [rows, mode, disable]
  );
  const getSearch = async (body, pageNumber) => {
    try {
      startLoading();
      const searchData = await apmcSearch(body);
      stopLoading();
      const data = transformDataApmc(searchData, pageNumber, ROW_PER_PAGE);
      // const data =
      //   searchData?.result?.data?.map((item, index) => ({
      //     no: index + 1,
      //     // [API_NAME_APMC.ROW_NUMBER]: item?.rowNumber,
      //     [API_NAME_APMC.ROW_NUMBER]: index + 1,
      //     [API_NAME_APMC.FORECAST_MONTH]: item?.forecastMonth,
      //     [API_NAME_APMC.DATA_TYPE]: item?.source,
      //     [API_NAME_APMC.MODE]: item?.mode,
      //     [API_NAME_APMC.DATA_OWNER]: item?.dataOwner,

      //     [API_NAME_APMC.ORG_FILE_NAME]: item?.orgFileName,
      //     [API_NAME_APMC.FILE_UPLOAD_ID]: item?.fileUploadId,
      //     [API_NAME_APMC.FILE_NAME]: item?.fileName,

      //     [API_NAME_APMC.UPLOAD_IMP_EXP_CD]: item?.uploadImpExpCd,
      //     [API_NAME_APMC.UPLOAD_STATUS]: item?.uploadSts,
      //     [API_NAME_APMC.UPLOAD_DATE]: item?.uploadDt,
      //     [API_NAME_APMC.UPLOAD_BY]: item?.uploadBy,

      //     [API_NAME_APMC.USE_STATUS]: item?.useSts,
      //     [API_NAME_APMC.USE_DATE]: item?.useDt,
      //     [API_NAME_APMC.USE_BY]: item?.useBy,

      //     [API_NAME_APMC.MODULE_ID]: item?.moduleId,
      //     [API_NAME_APMC.FUNCTION_ID]: item?.functionId,
      //     [API_NAME_APMC.D_HODTCRE]: item?.dHODTCRE,
      //     [API_NAME_APMC.API_ID]: item?.aplId,
      //   })) ?? [];

      return { searchData, data };
    } catch (error) {
      stopLoading();
      const errors = responseErrors(error);
      setMsgError([`${errors}`]);
      window.scrollTo(0, 0);
      return;
    }
  };

  useEffect(() => {
    if (dataTypeList) dataTypeList.sort((a, b) => a.value.toLowerCase().localeCompare(b.value.toLowerCase()));
    return;
  }, [dataTypeList]);
  return (
    <Fragment>
      <Box sx={{ padding: "1rem" }}>
        <HeaderContentBar title="WDN92020 : APMC Forecast Screen" />

        <AlertMessage type={"warning"} message={msgError} />
        <AlertMessage type={"success"} message={msgAlert} />
        <Grid container spacing={0} sx={{ mt: 4, alignItems: "center" }}>
          <Grid item xs={12}>
            <FilterBar
              form={form}
              setForm={setForm}
              mode={mode}
              setMode={setMode}
              setMsg={{ setMsgAlert: setMsgAlert, setMsgError: setMsgError }}
              dataList={{
                modeList: modeList,
                dataTypeList: dataTypeList,
                uploadStatusList: uploadStatusList,
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <ButtonBar
              form={form}
              setForm={setForm}
              mode={mode}
              setMode={setMode}
              rows={rows}
              setRows={setRows}
              columns={columns}
              setOnSearch={setOnSearch}
              setDisable={setDisable}
              loading={{ startLoading, stopLoading }}
              dataList={{
                impExpList: impExpList,
                modeList: modeList,
                dataTypeList: dataTypeList,
              }}
              getSearch={getSearch}
              setMsg={{ setMsgAlert: setMsgAlert, setMsgError: setMsgError }}
              pagination={{
                pagination: pagination,
                pageNumber: pageNumber,
                setPagination: setPagination,
                setPageNumber: setPageNumber,
              }}
              rowSelection={{
                rowSelectionModel: rowSelectionModel,
                setRowSelectionModel: setRowSelectionModel,
                rowModesModel: rowModesModel,
                setRowModesModel: setRowModesModel,
              }}
              functionId={"WDN92020"}
            />
          </Grid>

          {onSearch && (
            <Grid item xs={12}>
              <Table
                form={form}
                dataList={{
                  impExpList: impExpList,
                  dataTypeList: dataTypeList,
                  modeList: modeList,
                }}
                mode={mode}
                setMode={setMode}
                rows={rows}
                setRows={setRows}
                columns={columns}
                onSearch={onSearch}
                setOnSearch={setOnSearch}
                getSearch={getSearch}
                setMsg={{ setMsgAlert: setMsgAlert, setMsgError: setMsgError }}
                pagination={{
                  pagination: pagination,
                  pageNumber: pageNumber,
                  setPagination: setPagination,
                  setPageNumber: setPageNumber,
                }}
                rowSelection={{
                  rowSelectionModel: rowSelectionModel,
                  setRowSelectionModel: setRowSelectionModel,
                  rowModesModel: rowModesModel,
                  setRowModesModel: setRowModesModel,
                }}
                loading={{ startLoading, stopLoading }}
              />
            </Grid>
          )}
        </Grid>
      </Box>
    </Fragment>
  );
}
