import React, { Fragment } from "react";
// Utils
import { responseDownloadFileErrors } from "utils";
//  COMPONENTS
import { Box } from "@mui/material";
import { LinkFile } from "components/UI";
import { TEMPLATE_NAME } from "pages/Forecast/ApmcForecastScreen/constants/apmc.type.js";
import { API_NAME_APMC, TEMPLATE_FILE_CODE } from "shared/constants";
//API
import { useDownloadTemplateMutation } from "shared/services/inventory-forecast";
import { userProfile } from "constant";
import { useLoadingContext } from "shared/contexts/LoadingContext";
export default function TemplateBar({ setMsg: { setMsgError, setMsgAlert } }) {
  const exportTemplate = useDownloadTemplateMutation();
  const { startLoading, stopLoading } = useLoadingContext();
  // ? HANDLE LINK DOWNLOAD FILE TEMPLATE
  const handleDownloadTemplate = async name => {
    setMsgError([]);
    setMsgAlert([]);
    try {
      const infoFile = TEMPLATE_FILE_CODE.find(v => v.value === name);
      const body = { [API_NAME_APMC.DATA_OWNER]: userProfile.dataOwner, cd: infoFile?.cd };
      startLoading();
      await exportTemplate(body);
      stopLoading();
    } catch (error) {
      stopLoading();
      const err = responseDownloadFileErrors(error);
      setMsgError(err);
      window.scroll(0, 0);
      return;
    }
  };
  return (
    <Fragment>
      <Box sx={{ display: "flex", fontSize: "10px", flexWrap: "wrap", alignItems: "center" }}>
        <LinkFile
          id={TEMPLATE_NAME.PRODUCTION}
          component="button"
          variant="body2"
          value={TEMPLATE_NAME.PRODUCTION}
          onClick={() => handleDownloadTemplate("Production Plan")}
        />
        <div style={{ marginRight: "8px" }}> |</div>
        <LinkFile
          id="plan-template-packing"
          component="button"
          variant="body2"
          value={TEMPLATE_NAME.PACKING}
          onClick={() => handleDownloadTemplate("Packing Plan")}
        />
        <div style={{ marginRight: "8px" }}>|</div>
        <LinkFile
          id="plan-template-return=shipping"
          component="button"
          variant="body2"
          value={TEMPLATE_NAME.RETURN_SHIPPING}
          onClick={() => handleDownloadTemplate("Return Shipping Plan")}
        />
        <div style={{ marginRight: "8px" }}>|</div>
        <LinkFile
          id="plan-template-transferring"
          component="button"
          variant="body2"
          value={TEMPLATE_NAME.TRANSFERRING}
          onClick={() => handleDownloadTemplate("Transferring Plan")}
        />
        <div style={{ marginRight: "8px" }}>|</div>
        <LinkFile
          id="plan-template-receiving"
          component="button"
          variant="body2"
          value={TEMPLATE_NAME.RECEIVING}
          onClick={() => handleDownloadTemplate("Receiving Plan")}
        />
      </Box>
    </Fragment>
  );
}
