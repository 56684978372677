import React, { Fragment } from "react";
import { Grid } from "@mui/material";
import { DropDown, InputTextField } from "components/UI";
import { ModeAction } from "state/enum";
import { API_SYSTEM_MASTER, FIRST_PAGE } from "shared/constants";
import { userProfile } from "constant";
export default function FilterBar(props) {
  const {
    mode,
    setMode,
    form,
    setForm,
    setRows,
    setOnSearch,
    setMsg: { setMsgAlert, setMsgError },
    dataList: { categoryList, subCategoryList },
    pagination: { pagination, pageNumber, setPagination, setPageNumber },
    rowSelection: {
      rowSelectionModel,
      rowModesModel,
      setRowSelectionModel,
      setRowModesModel,
    },
  } = props;
  const handleChange = (e) => {
    setMsgError([]);
    setMsgAlert([]);

    setForm((prev) => ({
      ...prev,
      [API_SYSTEM_MASTER.CATEGORY]: e.target.value,
    }));

    setForm({
      userId: userProfile?.userId,
      [API_SYSTEM_MASTER.DATA_OWNER]: userProfile?.dataOwner,
      [API_SYSTEM_MASTER.CATEGORY]: e.target.value,
      [API_SYSTEM_MASTER.SUB_CATEGORY]: "",
      [API_SYSTEM_MASTER.CODE]: "",
      [API_SYSTEM_MASTER.VALUE]: "",
      [API_SYSTEM_MASTER.REMARK]: "",
      [API_SYSTEM_MASTER.STATUS]: "",
      [API_SYSTEM_MASTER.STATUS_ID]: "",
      [API_SYSTEM_MASTER.CREATE_BY]: "",
      [API_SYSTEM_MASTER.CREATE_DATE]: "",
      [API_SYSTEM_MASTER.UPDATE_BY]: "",
      [API_SYSTEM_MASTER.UPDATE_DATE]: "",
    });

    setMode(ModeAction.VIEW);
    setOnSearch(false);
    // Reset state table
    setRowSelectionModel([]);
    setRowModesModel({});
    setRows([]);
    setPagination({});
    setPageNumber(FIRST_PAGE);
  };
  return (
    <Fragment>
      <Grid container spacing={1} sx={{ md: 4, alignItems: "center" }}>
        <Grid item xs={2}>
          <strong>*Category: </strong>
        </Grid>
        <Grid item xs={3}>
          <DropDown
            id="select_category"
            required
            value={form?.category}
            onChange={handleChange}
            autoFocus={true}
            menu={categoryList?.map((val) => ({
              key: val?.category,
              value: val?.category,
            }))}
            disabled={[ModeAction.ADD, ModeAction.EDIT].includes(mode)}
            defaultValue=""
            placeholder="<Select>"
          />
        </Grid>

        <Grid item xs={1}></Grid>
        <Grid item xs={2}>
          <strong>SubCategory: </strong>
        </Grid>
        <Grid item xs={4}>
          <DropDown
            id="select_sub_category"
            value={form?.subCategory}
            onChange={(e) => {
              setForm((prev) => ({
                ...prev,
                [API_SYSTEM_MASTER.SUB_CATEGORY]: e.target.value,
              }));
            }}
            menu={subCategoryList?.map((val) => ({
              key: val?.subCategory,
              value: val?.subCategory,
            }))}
            disabled={[ModeAction.ADD, ModeAction.EDIT].includes(mode)}
            defaultValue=""
            placeholder="<All>"
          />
        </Grid>

        <Grid item xs={2}>
          <strong>Code: </strong>
        </Grid>
        <Grid item xs={3}>
          <InputTextField
            id="input_code"
            style={{ textAlign: "left" }}
            fullWidth
            value={form?.code}
            multiline={true}
            maxLength={40}
            onChange={(e) => {
              setForm((prev) => ({
                ...prev,
                [API_SYSTEM_MASTER.CODE]: e.target.value?.toUpperCase(),
              }));
            }}
            // regularExp={/^[0-9a-zA-Z]{0,40}$|^[0-9a-zA-Z]{0,39}\*?$/}
            disabled={[ModeAction.ADD, ModeAction.EDIT].includes(mode)}
          />
        </Grid>
      </Grid>
    </Fragment>
  );
}

// TooLongString123456 (more than 10 characters)
// Invalid@Symbol* (contains invalid character '@')
// Short* (less than 9 characters before '*')
// Space In Between* (contains space in between)
// SpecialCharacter$ (contains special character '$')
