import React, { Fragment } from "react";
import DataTable from "pages/DataTable";
import { API_LOG_MONITORING, PAGINATION, ROW_PER_PAGE } from "shared/constants";
import { responseErrors } from "utils";
import { useLogSummarySearchMutation } from "shared/services/common/monitoring";
import { DataGridTable } from "components/UI";

export default function TableBar(props) {
  const {
    searchForm,
    mode,
    setMode,
    onSearch,
    rows,
    setRows,
    columns,
    setMsg: { setMsgError, setMsgAlert },
    pagination: { pagination, setPagination, pageNumber, setPageNumber },
    rowSelection: { rowSelectionModel, setRowSelectionModel, rowModesModel, setRowModesModel },
  } = props;
  const searchSummary = useLogSummarySearchMutation();
  const handleChangePagination = async (event, value) => {
    try {
      setPageNumber(value);
      const body = {
        [API_LOG_MONITORING.DATA_OWNER]: searchForm?.dataOwner,
        [API_LOG_MONITORING.MODULE_ID]: searchForm?.moduleId,
        funtionId: searchForm?.functionId,
        [API_LOG_MONITORING.STATUS_ID]: searchForm?.statusId,
        [API_LOG_MONITORING.USER_ID]: searchForm?.userId,
        [API_LOG_MONITORING.APP_ID]: searchForm?.appId,
        [API_LOG_MONITORING.DATE_FORM]: !searchForm?.dateFrom ? "" : searchForm?.dateFrom,
        [API_LOG_MONITORING.DATE_TO]: !searchForm?.dateTo ? "" : searchForm?.dateTo,
        [PAGINATION.PAGE_NUMBER]: value,
        [PAGINATION.ROW_PER_PAGE]: ROW_PER_PAGE,
      };
      const searchData = await searchSummary(body);
      const data =
        searchData?.result?.data?.map((item, index) => ({
          no: index + 1,
          [API_LOG_MONITORING.ROW_NUMBER]: item?.rowNumber,
          [API_LOG_MONITORING.APP_ID]: item?.aplId,
          [API_LOG_MONITORING.MODULE_ID]: item?.moduleId,
          [API_LOG_MONITORING.FUNCTION_ID]: item?.functionId,
          [API_LOG_MONITORING.START_DATE]: item?.startDate,
          [API_LOG_MONITORING.END_DATE]: item?.endDate,
          [API_LOG_MONITORING.START_TIME]: item?.startDateT,
          [API_LOG_MONITORING.END_TIME]: item?.endDateT,
          [API_LOG_MONITORING.USER_ID]: item?.userId,
          [API_LOG_MONITORING.STATUS]: item?.status,
          [API_LOG_MONITORING.MESSAGE]: item?.message,
          [API_LOG_MONITORING.FUNCTION_NAME]: item?.funtionName,
          [API_LOG_MONITORING.MODULE_NAME]: item?.moduleName,
        })) ?? [];
      setPagination(searchData?.result?.pagination ?? {});
      setPageNumber(searchData?.result?.pagination?.pageNumber);
      setRows(data);
      return;
    } catch (error) {
      const errors = responseErrors(error);
      setMsgError(errors);
      window.scrollTo(0, 0);
      return;
    }
  };

  return (
    <Fragment>
      <DataGridTable
        mode={mode}
        onSearch={onSearch}
        rowSelection={{
          rowModesModel: rowModesModel,
          setRowModesModel: setRowModesModel,
          rowSelectionModel: rowSelectionModel,
          setRowSelectionModel: setRowSelectionModel,
        }}
        setMode={setMode}
        rows={rows}
        autoHeight={true}
        processRowUpdate={null}
        pagination={{
          pagination: pagination,
          pageNumber: pageNumber,
          handleChangePagination: handleChangePagination,
        }}
        column={{
          columns: columns,
          columnGroupingModel: null,
          columnVisibilityModel: {
            [API_LOG_MONITORING.STATUS_ID]: false,
            [API_LOG_MONITORING.START_TIME]: false,
            [API_LOG_MONITORING.END_TIME]: false,
            [API_LOG_MONITORING.FUNCTION_ID]: false,
            [API_LOG_MONITORING.MODULE_ID]: false,
          },
        }}
        checkboxSelection={false}
      />
    </Fragment>
  );
}
