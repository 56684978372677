import React, { useState, useMemo, useEffect } from "react";
import { Grid, Box, Divider } from "@mui/material";
import { InputButton } from "../../components/UI";
import { GridRowModes } from "@mui/x-data-grid";
import { ModeAction } from "state/enum";

const ActionBar = ({
  mode,
  rows,
  columns,
  setRows,
  setMode,
  setMsgError,
  setMsgAlert,
  onSearch,
  setOnSearch,
  setRowModesModel,
  setRowSelectionModel,
  rowSelectionModel,
  rowModesModel,
  handleSearch,
  handleClear,
  handleDownloadExcel,
  handleDeleteClick,
  handleAdd,
  handleEdit,
  handleCopy,
  handleCopyClick: handleCopyClickProps,
  firstField,
  functionId,
}) => {
  const [searchButton, setSearchButton] = useState(false);
  const [clearButton, setClearButton] = useState(false);
  const [downloadButton, setDownloadButton] = useState(false);
  const [addButton, setAddButton] = useState(false);
  const [copyButton, setCopyButton] = useState(false);
  const [editButton, setEditButton] = useState(false);
  const [deleteButton, setDeleteButton] = useState(false);

  useEffect(() => {
    if (!rows?.length) {
      setAddButton(false);
      setCopyButton(true);
      setEditButton(true);
      setDeleteButton(true);
    }
  }, [rows]);

  useMemo(() => {
    switch (mode) {
      case ModeAction.VIEW:
        setSearchButton(false);
        setClearButton(false);
        setDownloadButton(false);
        setAddButton(false);
        setCopyButton(true);
        setEditButton(true);
        setDeleteButton(true);
        break;

      case ModeAction.ADD:
        setSearchButton(true);
        setClearButton(true);
        setDownloadButton(true);
        setAddButton(true);
        setCopyButton(true);
        setEditButton(true);
        setDeleteButton(true);
        break;

      case ModeAction.EDIT:
        setSearchButton(true);
        setClearButton(true);
        setDownloadButton(true);
        setAddButton(true);
        setCopyButton(true);
        setEditButton(true);
        setDeleteButton(true);
        break;

      case ModeAction.SELECTED:
        setSearchButton(false);
        setClearButton(false);
        setDownloadButton(false);
        // setAddButton(true);
        setCopyButton(false);
        setEditButton(false);
        setDeleteButton(false);
        break;

      default:
        break;
    }
  }, [mode]);

  const handleAddClick = () => {
    console.log(`handleAddClick >>`);
    let maxNo = -1;
    setMode(ModeAction.ADD);
    setMsgError([]);
    setMsgAlert([]);
    setOnSearch(true);
    setAddButton(true);

    const field = {};
    columns.forEach(column => {
      if (column.field === "rowNumber") {
        maxNo = !rows || rows.length === 0 ? 1 : Math.max(...rows.map(item => item.rowNumber));
        field[column.field] = rows.length ? maxNo + 1 : maxNo;
      } else {
        field[column.field] = "";
      }
    });

    setRows(oldRows => [
      ...oldRows,
      {
        ...field,
        isNew: true,
      },
    ]);

    const maxNoModel = rows.length ? maxNo + 1 : maxNo;

    setRowModesModel(oldModel => ({
      ...oldModel,
      [maxNoModel]: { mode: GridRowModes.Edit },
    }));

    setRowSelectionModel([maxNoModel]);
  };

  const handleCopyClick = () => {
    setMode(ModeAction.ADD);
    setMsgError([]);
    setMsgAlert([]);
    setOnSearch(true);
    setDownloadButton(true);

    const rowCopy = rows.find(v => v.rowNumber === rowSelectionModel[0]);

    console.log("+++rows", rows);
    console.log("+++rowCopy", rowCopy);

    setRows(oldRows => [
      ...oldRows,
      {
        ...rowCopy,
        rowNumber: rows.length + 1,
        isNew: true,
      },
    ]);

    setRowModesModel(oldModel => ({
      ...oldModel,
      [rows.length + 1]: { mode: GridRowModes.Edit },
    }));

    setRowSelectionModel([rows.length + 1]);
  };

  const handleEditClick = () => {
    setMode(ModeAction.EDIT);
    setMsgError([]);
    setMsgAlert([]);

    let tempRowModesModel = rowModesModel;

    const rowSelect = rowSelectionModel.map(val => {
      tempRowModesModel = {
        ...tempRowModesModel,
        [val]: { mode: GridRowModes.Edit },
      };
    });

    if (rowSelect) {
      setRowModesModel(tempRowModesModel);
      // setRowSelectionModel()
    }
  };

  return (
    <Box sx={{ padding: "1rem" }}>
      <Grid container spacing={2}>
        <Grid item xs={12} sx={{ display: "flex", justifyContent: "flex-end", mt: 2 }}>
          <InputButton id="btn_search" value={"Search"} disabled={searchButton} onClick={handleSearch} name={`${functionId}Search`}/>
          <InputButton id="btn_clear" value={"Clear"} disabled={clearButton} onClick={handleClear} name={`${functionId}Clear`}/>
          <InputButton id="btn_download" value={"Download"} disabled={downloadButton} onClick={handleDownloadExcel} name={`${functionId}Download`}/>
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={12} sx={{ display: "flex", justifyContent: "flex-end" }}>
          <InputButton
            id="btn_add"
            value={"Add"}
            onClick={handleAdd || handleAddClick}
            disabled={addButton}
            onKeyDown={e => {
              if (!onSearch && e.key === "Tab") {
                e.preventDefault();
                document.getElementById(firstField).focus();
              }
            }}
            name={`${functionId}Add`}
          />
          <InputButton
            id="btn_copy"
            value={"Copy"}
            onClick={handleCopy || handleCopyClickProps || handleCopyClick}
            disabled={copyButton}
            name={`${functionId}Copy`}
          />
          <InputButton id="btn_edit" value={"Edit"} onClick={handleEdit || handleEditClick} disabled={editButton} name={`${functionId}Edit`}/>
          <InputButton id="btn_delete" value={"Delete"} onClick={handleDeleteClick} disabled={deleteButton} name={`${functionId}Delete`}/>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ActionBar;
