import { Box, Link, Stack } from "@mui/material";
import { HeaderContentBar } from "components/Layout";
import { AlertMessage, DropDown } from "components/UI";
import { useConfirmDialogContext } from "context/confirmDialogContext";
import React, { Fragment, useEffect, useMemo, useState } from "react";
import CriterialSection from "./components/CriterialSection";
import { userProfile } from "constant";
import {
  useExportInvoiceCategoryBrokerQuery,
  useExportInvoiceCategoryCurrencyQuery,
  useExportInvoiceCategoryQuery,
  // useExportInvoiceCategoryVanningPlantQuery,
  useExportInvoiceEmUserQuery,
  useExportInvoiceExpCompanyQuery,
  useExportInvoiceOrderTypeQuery,
  // useExportInvoicePackingMonthQuery,
  useExportInvoiceSearchSubScreenMutation,
  useExportInvoiceShipmentCarrierMutation,
  useExportInvoiceShipmentEtdMutation,
  // useExportInvoiceShipmentEtdQuery,
} from "shared/services/invoice";
import ButtonCriterial from "./components/ButtonCriterial";
import { FIRST_PAGE, PAGINATION, ROW_PER_PAGE } from "shared/constants";
import DetailSection from "./components/DetailSection";
import TableSection from "./components/TableSection";
import { useColumnCreationSubScreen } from "../../hooks/useColumnCreationSubScreen";
import { ModeAction } from "state/enum";
import { transformSubScreenData } from "../../hooks/tranformData";
import { findObject, responseErrors } from "utils";
import dayjs from "dayjs";
import { DEFAULT_STATE_WDN95011 } from "../../constants/constants";

export default function CreateInvoiceSubScreen({
  onOpen,
  setOnOpen,
  // modeOfWDN95011,
  // setModeOfWDN95011,
  alertMain,
  form,
  setForm,
}) {
  const confirmDialogCtx = useConfirmDialogContext();
  const [msgError, setMsgError] = useState([]);
  const [msgAlert, setMsgAlert] = useState([]);
  const [mode, setMode] = useState(ModeAction.VIEW);
  const [onSearch, setOnSearch] = useState(false);

  const [onProcess, setOnProcess] = useState(false);

  // Table
  const [rows, setRows] = useState([]);
  const [rowModesModel, setRowModesModel] = useState({});
  const [rowSelectionModel, setRowSelectionModel] = useState([]);

  const [pagination, setPagination] = useState({});
  const [pageNumber, setPageNumber] = useState(FIRST_PAGE);

  //Button State
  const [searchBtn, setSearchBtn] = useState(false);
  const [clearBtn, setClearBtn] = useState(false);
  // const [addBtn, setAddBtn] = useState(false);
  // const [copyBtn, setCopyBtn] = useState(false);
  // const [editBtn, setEditBtn] = useState(false);
  // const [deleteBtn, setDeleteBtn] = useState(false);

  const [emUser, setEmUser] = useState(null);
  const [shipmentEtdData, setShipmentEtdData] = useState([]);
  const [shipCarierData, setShipCarierData] = useState([]);

  // API
  const { data: importerData } = useExportInvoiceCategoryQuery(userProfile.dataOwner);
  const { data: invoiceOrderTypeData } = useExportInvoiceOrderTypeQuery();
  // const { data: ImpCompanyData } = useExportInvoiceImpCompanyQuery();
  const { data: expData } = useExportInvoiceExpCompanyQuery(userProfile.comAbbr);
  // const { data: parkingMonthData } = useExportInvoicePackingMonthQuery();
  // const { data: vanningPlantData } =useExportInvoiceCategoryVanningPlantQuery();
  const { data: brokerData } = useExportInvoiceCategoryBrokerQuery("B", userProfile.dataOwner, "CMP_2111_000001");
  const { data: currencyData } = useExportInvoiceCategoryCurrencyQuery(userProfile.dataOwner);

  const emUserResult = useExportInvoiceEmUserQuery();

  const searchExportSubScreenInfo = useExportInvoiceSearchSubScreenMutation();

  const shipmentEtd = useExportInvoiceShipmentEtdMutation();
  const shipmentCarrier = useExportInvoiceShipmentCarrierMutation();

  // columns
  const columns = useMemo(() => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    return useColumnCreationSubScreen({
      rows,
      mode: mode,
      dataList: {},
    });
  }, [rows, mode]);

  const handleClose = async () => {
    const confirm = await confirmDialogCtx.success({ type: "closeScreen" });
    if (!confirm) {
      return;
    }
    setForm(DEFAULT_STATE_WDN95011);
    setOnOpen({ open: false });
  };

  const refreshRowsTable = async () => {
    try {
      const body = {
        dataOwner: userProfile.dataOwner,
        nonCommInvHId: form?.nonCommInvHId ?? "", // TODO: mock here
        invoiceNo: form?.invNo,
        invoiceOrderType: form?.invType,
        etd: form?.etdDt,
        vesselName: form?.vesselName,
        importerId: form?.impCompanyId,
        importerCompanyAbbr: !form?.impCompanyId
          ? ""
          : findObject({
              data: importerData,
              value: form?.impCompanyId,
              property: "companyId",
              field: "companyAbbr",
            }),
        exporterId: !form?.expCd
          ? ""
          : findObject({
              data: expData,
              value: form?.expCd,
              property: "plantId",
              field: "exporterId",
            }),
        exporterCompanyAbbr: !form?.expCd
          ? ""
          : findObject({
              data: expData,
              value: form?.expCd,
              property: "plantId",
              field: "exporterCompanyAbbr",
            }),
        exporterCd: !form?.expCd
          ? ""
          : findObject({
              data: expData,
              value: form?.expCd,
              property: "plantId",
              field: "exporterCd",
            }),
        parkingMonth: form?.parkingMonth,
        vanningPlant: form?.vanningPlant,
        brokerId: form?.expBrokerCompanyId,
        loginUserCompanyAbbr: userProfile.comAbbr,
        [PAGINATION.PAGE_NUMBER]: FIRST_PAGE,
        [PAGINATION.ROW_PER_PAGE]: ROW_PER_PAGE,
      };
      const searchData = await searchExportSubScreenInfo(body);

      if (!searchData?.result?.data?.length) {
        setOnSearch(false);
        setPageNumber(FIRST_PAGE);
        setMode(ModeAction.VIEW);
        setRows([]);
        setRowSelectionModel([]);
        setRowModesModel({});
        setPagination({});
        return;
      }
      setRowSelectionModel([]);
      setRowModesModel({});
      setMode(ModeAction.VIEW);
      const data = transformSubScreenData(searchData?.result?.data);
      setRows(data);
      setPagination(searchData?.result?.pagination ?? {});
      setPageNumber(searchData?.result?.pagination?.pageNumber);
      setOnSearch(true);
      return true;
    } catch (error) {
      const errors = responseErrors(error);
      setMsgError(errors);
      window.scrollTo(0, 0);
      return false;
    }
  };

  useEffect(() => {
    if (onOpen.isEdit) {
      // console.log(onOpen.data);
      setForm(prev => ({
        ...prev,
        invNo: onOpen.data.invNo,
        etdDt: onOpen.data.etdDt,
        impCompanyId: onOpen.data.impCompanyId,
        // parkingMonth: onOpen.data.expCd,
        expBrokerCompanyId: onOpen.data.expBrokerCompanyId,

        invType: onOpen.data.invType,
        vesselName: onOpen.data.vesselName,
        expCd: !form?.expCd
          ? ""
          : findObject({
              data: expData,
              value: onOpen.data.expCd,
              property: "exporterAbbr",
              field: "exporterCompanyCd",
            }),
        // vanningPlant: onOpen.data.expCd,

        expCustomsStkDt: onOpen.data.expCustomsStkDt,
        currencyCd: onOpen.data.currencyCd,
        nonCommInvHId: onOpen.data.nonCommInvHId,

        expBrokerCompanyAbbr: onOpen.data.expBrokerCompanyAbbr,
        expEntryNo: onOpen.data.expEntryNo,
      }));

      // กำหนดเวลาที่ต้องการตรวจสอบ
      const someDate = dayjs(onOpen.data.etdDt, "DD/MM/YYYY");
      // ตรวจสอบว่าเวลานี้น้อยกว่าปัจจุบันหรือไม่
      const isBeforeNow = someDate.isBefore(dayjs(), "date");

      // console.log(someDate, isBeforeNow);

      setMsgError([]);

      if (isBeforeNow) {
        setMsgError([
          `MSTD1006AERR: Cannot cancel or edit Non-commercial invoice no, ${onOpen.data.invNo}, because ETD = ${onOpen.data.etdDt} already passed\n`,
        ]);
      }

      setOnProcess(true);
      setSearchBtn(true);
      setClearBtn(true);
      setMode(ModeAction.EDIT);
    }
  }, [onOpen]);

  useEffect(() => {
    if (userProfile.comAbbr) {
      emUserResult({ companyAbbr: userProfile.comAbbr }).then(res => {
        if (res.statusCode === 200) {
          setEmUser(res.result.isEMUser);
        }
      });
    } else {
      setEmUser(null);
    }

    const fetchData = async () => {
      try {
        if (form?.impCompanyId && form?.expCd) {
          const { result: shipmentEtdResultData } = await shipmentEtd({
            dataOwner: userProfile.dataOwner,
            impCompanyId: form?.impCompanyId,
            expCd: form?.expCd
              ? findObject({
                  data: expData,
                  value: form?.expCd,
                  property: "plantId",
                  field: "exporterCd",
                })
              : "",
            loginUserCompanyAbbr: userProfile.comAbbr,
          });

          setShipmentEtdData(shipmentEtdResultData);
        }

        if (form?.impCompanyId && form?.etdDt && form?.shipmentCd) {
          const body = {
            dataOwner: userProfile.dataOwner,
            shipmentCd: !form?.shipmentCd
              ? ""
              : shipmentEtdData !== null && shipmentEtdData.length > 0
                ? findObject({
                    data: shipmentEtdData,
                    value: form?.shipmentCd,
                    property: "shipmentCd",
                    field: "shipmentLabel",
                  })
                : "",
            etdDt: form?.etdDt,
            impCompanyId: form?.impCompanyId,
            impCompanyAbbr: !form?.impCompanyId
              ? ""
              : findObject({
                  data: importerData,
                  value: form?.impCompanyId,
                  property: "companyId",
                  field: "companyAbbr",
                }),
            expCd: form?.expCd
              ? findObject({
                  data: expData,
                  value: form?.expCd,
                  property: "plantId",
                  field: "exporterCd",
                })
              : "",
            expCompanyAbbr: form?.expCd
              ? findObject({
                  data: expData,
                  value: form?.expCd,
                  property: "plantId",
                  field: "exporterCompanyAbbr",
                })
              : "",
          };
          //  {
          //   dataOwner: "TMATH",
          //   shipmentCd: "TDEM303D202408001 | 10.08.2024",
          //   etdDt: "10/08/2024",
          //   impCompanyId: "CMP_1208_000009",
          //   expCd: "722F",
          //   expCompanyAbbr: "TMT",
          // };

          const { result: shipCarierResultData } = await shipmentCarrier(body);

          setShipCarierData(shipCarierResultData);
        }
      } catch (error) {
        setShipmentEtdData([]);
        setShipCarierData([]);
      }
    };
    fetchData();
  }, [form?.impCompanyId, form?.expCd, form?.shipmentCd, form?.etdDt]);

  return (
    <Fragment>
      <Stack>
        <Stack>
          <Link href="#" color="#000" onClick={handleClose} size="small" sx={{ fontSize: "12px" }}>
            Close
          </Link>
        </Stack>
        <Stack mb={2}>
          <HeaderContentBar title="WDN95011 : Create Invoice Sub Screen" />
          <AlertMessage type={"warning"} message={msgError} />
          <AlertMessage type={"success"} message={msgAlert} />
        </Stack>
        <Stack sx={{ pl: "1rem", pr: "1rem" }}>
          <CriterialSection
            mode={mode}
            form={form}
            setForm={setForm}
            onOpen={onOpen}
            dataList={{
              invoiceOrderTypeData,
              expData,
              brokerData,
              importerData,
            }}
          />
          <ButtonCriterial
            form={form}
            setForm={setForm}
            onOpen={onOpen}
            onProcess={onProcess}
            onSearch={onSearch}
            setOnSearch={setOnSearch}
            mode={mode}
            setMode={setMode}
            rows={rows}
            setRows={setRows}
            stateButton={{ searchBtn, clearBtn }}
            setMsg={{ setMsgAlert, setMsgError }}
            rowSelection={{
              rowModesModel,
              setRowModesModel,
              rowSelectionModel,
              setRowSelectionModel,
            }}
            pagination={{
              pagination,
              setPagination,
              pageNumber,
              setPageNumber,
            }}
            dataList={{
              invoiceOrderTypeData,
              // ImpCompanyData,
              expData,
              // parkingMonthData,
              // vanningPlantData,
              brokerData,
              importerData,
            }}
            functionId={"WDN95010"}
          />
          {onSearch ? (
            <Box sx={{ pr: "1rem", pl: "1rem" }}>
              <DetailSection
                onOpen={onOpen}
                isEMUser={emUser}
                mode={onOpen?.isEdit ? ModeAction.EDIT : null}
                form={form}
                setForm={setForm}
                dataList={{ currencyData, shipmentEtdData, shipCarierData }}
              />
              <TableSection
                onOpen={onOpen}
                mode={mode}
                setMode={setMode}
                setOnOpen={setOnOpen}
                rows={rows}
                setRows={setRows}
                columns={columns}
                form={form}
                setForm={setForm}
                alertMain={alertMain}
                // formRPkg={formRPkg}
                // formContainer={formContainer}
                refreshRowsTable={refreshRowsTable}
                setOnSearch={setOnSearch}
                setMsg={{ setMsgAlert, setMsgError }}
                rowSelection={{
                  rowModesModel,
                  setRowModesModel,
                  rowSelectionModel,
                  setRowSelectionModel,
                }}
                pagination={{
                  pagination,
                  setPagination,
                  pageNumber,
                  setPageNumber,
                }}
                dataList={{
                  importerData,
                  expData,
                  shipCarierData,
                  //   importerList,
                  //   consigneeList,
                  //   brokerList,
                }}
                isEMUser={emUser}
              />
            </Box>
          ) : (
            <Stack mb={80}></Stack>
          )}
          {/* <Box>
            <DropDown
              id="select-comAbbr"
              fullWidth
              required
              defaultValue=""
              placeholder="<All>"
              value={companyAbbr}
              onChange={e => setCompanyAbbr(e.target.value)}
              menu={[
                { compAbbr: "TMT" },
                { compAbbr: "TKM" },
                { compAbbr: "TMV" },
                { compAbbr: "KUOZUI" },
                { compAbbr: "TASA" },
                { compAbbr: "TMP" },
                { compAbbr: "TAP" },
                { compAbbr: "TMMIN" },
              ].map(val => ({
                key: val?.compAbbr,
                value: val?.compAbbr,
              }))}
              disabled={[ModeAction.ADD, ModeAction.EDIT].includes(mode)}
            />
          </Box> */}
        </Stack>
      </Stack>
    </Fragment>
  );
}
