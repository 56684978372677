import { Grid } from "@mui/material";
import { CheckBox, DatePickerField, DropDown, InputTextField } from "components/UI";
import { userProfile } from "constant";
import React, { Fragment } from "react";
import { ModeAction } from "state/enum";

export default function DetailSection(props) {
  const {
    mode,
    form,
    setForm,
    isEMUser,
    dataList: { currencyData, shipmentEtdData, shipCarierData },
    onOpen,
  } = props;

  // expCustomStockDt
  // nonComInvFlag
  // broker
  // currencyCd
  // expEntryNo

  const handleCheckNonComInvFlag = e => {
    setForm(prev => ({
      ...prev,
      nonComInvFlag: e.target.checked,
    }));
  };

  const handleChangeCurrencyCd = e => {
    setForm(prev => ({
      ...prev,
      currencyCd: e.target.value,
    }));
  };

  const handleChangeShipmentCd = e => {
    setForm(prev => ({
      ...prev,
      shipmentCd: e.target.value,
    }));
  };

  const handleChangeShipCarierCompanyAbbr = e => {
    setForm(prev => ({
      ...prev,
      shipCarierCompanyAbbr: e.target.value,
    }));
  };

  return (
    <Fragment>
      {/* Container #1 */}
      <Grid container spacing={1} alignItems="center">
        <Grid item xs={2}>
          <strong>Export Custom Stock Date : </strong>
        </Grid>
        <Grid item xs={2}>
          <DatePickerField
            id="input-export-custom-stock-date"
            className="input-export-custom-stock-date"
            onChange={e =>
              setForm(old => ({
                ...old,
                expCustomsStkDt: e,
              }))
            }
            value={form?.expCustomsStkDt}
            disabled={[ModeAction.ADD, ModeAction.EDIT].includes(mode)}
          />
        </Grid>
        <Grid item xs={3}>
          <CheckBox
            id="checkbox-non-com-inv-flag"
            placement=""
            label="Non-Commercial Invoice"
            sx={{
              color: "#000",
              fontFamily: "Kanit",
              fontWeight: "bold",
              ml: 1,
            }}
            checked={form?.nonComInvFlag}
            onChange={e => handleCheckNonComInvFlag(e)}
          />
        </Grid>
        <Grid item xs={2}>
          <strong>Broker : </strong>
        </Grid>
        <Grid item xs={2}>
          <InputTextField
            id="input-broker"
            fullWidth={true}
            maxLength={20}
            value={form?.expBrokerCompanyAbbr}
            // regularExp={/^[a-zA-Z0-9_*-]*$/}
            onChange={e =>
              setForm(old => ({
                ...old,
                expBrokerCompanyAbbr: e.target.value,
              }))
            }
            disabled={[ModeAction.ADD, ModeAction.EDIT].includes(mode)}
          />
        </Grid>
      </Grid>

      {/* Container #2 */}
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={2}>
          <strong>*Currency : </strong>
        </Grid>
        <Grid item xs={3}>
          <DropDown
            id="select-currency"
            fullWidth
            required
            defaultValue=""
            placeholder="<All>"
            value={form?.currencyCd}
            onChange={handleChangeCurrencyCd}
            menu={currencyData?.map(val => ({
              key: val?.cd,
              value: val?.cd,
            }))}
            disabled={[ModeAction.ADD, ModeAction.EDIT].includes(mode)}
          />
        </Grid>
        <Grid item xs={2} />
        <Grid item xs={2}>
          <strong>Export Entry No : </strong>
        </Grid>
        <Grid item xs={2}>
          <InputTextField
            id="input-export-entry-no"
            fullWidth={true}
            maxLength={20}
            value={form?.expEntryNo}
            // regularExp={/^[a-zA-Z0-9_*-]*$/}
            onChange={e =>
              setForm(old => ({
                ...old,
                expEntryNo: e.target.value,
              }))
            }
            disabled={[ModeAction.ADD, ModeAction.EDIT].includes(mode)}
          />
        </Grid>
      </Grid>
      {/* Container #3 */}
      {isEMUser !== null && isEMUser && (
        // {userProfile.userId !== "APMC" && (
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={2}>
            <strong>*Shipment Code|ETD : </strong>
          </Grid>
          <Grid item xs={3}>
            <DropDown
              id="select-shipment"
              fullWidth
              required
              defaultValue=""
              placeholder="<All>"
              value={form?.shipmentCd}
              onChange={handleChangeShipmentCd}
              menu={shipmentEtdData?.map(val => ({
                // key: val?.shipmentLabel,
                key: val?.shipmentCd,
                value: val?.shipmentLabel,
              }))}
              disabled={[ModeAction.ADD, ModeAction.EDIT].includes(mode)}
            />
          </Grid>
          <Grid item xs={2} />
          <Grid item xs={2}>
            <strong>Carrier : </strong>
          </Grid>
          <Grid item xs={2}>
            <DropDown
              id="select-ship-carier"
              fullWidth
              required
              defaultValue=""
              placeholder="<All>"
              value={form?.shipCarierCompanyAbbr}
              onChange={handleChangeShipCarierCompanyAbbr}
              menu={shipCarierData?.map(val => ({
                key: val?.shipCarierCompanyAbbr,
                value: val?.shipCarierCompanyAbbr,
              }))}
              disabled={[ModeAction.ADD, ModeAction.EDIT].includes(mode)}
            />
          </Grid>
        </Grid>
      )}
    </Fragment>
  );
}
