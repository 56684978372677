import React from "react";
// Components
import { Grid } from "@mui/material";
import { DatePickerField, InputTextField } from "components/UI";
import { API_LOG_MONITORING } from "shared/constants";

export default function SubCriterialBar(props) {
  const { searchForm, setSearchForm } = props;
  return (
    <React.Fragment>
      <Grid item flex="1">
        <strong style={{ fontSize: 14 }}>User ID : </strong>
      </Grid>
      <Grid item flex="2">
        <InputTextField
          id="input_userId"
          fullWidth={true}
          value={searchForm?.userId}
          onChange={e =>
            setSearchForm(prev => ({
              ...prev,
              [API_LOG_MONITORING.USER_ID]: e.target.value,
            }))
          }
        />
      </Grid>
      <Grid item flex="1">
        <strong style={{ fontSize: 14 }}>App ID : </strong>
      </Grid>
      <Grid item flex="2">
        <InputTextField
          id="input_appId"
          fullWidth={true}
          value={searchForm?.appId}
          onChange={e =>
            setSearchForm(prev => ({
              ...prev,
              [API_LOG_MONITORING.APP_ID]: e.target.value.trim(),
            }))
          }
          regularExp={/^[a-zA-Z0-9_*-]*$/}
        />
      </Grid>
      <Grid item flex="1">
        <strong>From : </strong>
      </Grid>
      <Grid item flex="3">
        <DatePickerField
          id="input_dateFrom"
          value={searchForm?.dateFrom}
          fullWidth={true}
          onChange={date => {
            setSearchForm(prev => ({
              ...prev,
              [API_LOG_MONITORING.DATE_FORM]: date,
            }));
          }}
        />
      </Grid>
      <Grid item flex="1">
        <strong>To : </strong>
      </Grid>
      <Grid item flex="3">
        <DatePickerField
          id="input_dateTo"
          fullWidth={true}
          value={searchForm?.dateTo}
          onChange={date =>
            setSearchForm(prev => ({
              ...prev,
              [API_LOG_MONITORING.DATE_TO]: date,
            }))
          }
        />
      </Grid>
    </React.Fragment>
  );
}
