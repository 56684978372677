/* eslint-disable react-hooks/rules-of-hooks */
import React, { Fragment, useMemo, useState, useEffect } from "react";
// Utils
import { responseErrors, messageDisplay } from "utils";
import { validationSearchForm } from "utils/validation";
// Hook
import { useColumns, useButtonMode } from "./hooks";
// Components
import { Grid, Box, Divider } from "@mui/material";
import { HeaderContentBar } from "components/Layout";
import { AlertMessage } from "components/UI";
import FilterBar from "./components/FilterBar";
import ButtonBar from "./components/ButtonBar";
import ActionTableBar from "./components/ActionTableBar";
import TableBar from "./components/TableBar";
// Service
import {
  useSystemMasterCategoryQuery,
  useSystemMasterSubCategoryQuery,
  useSystemMasterStatusQuery,
  useSystemMasterSearchMutation,
} from "shared/services/master-maintenance/system-master.service";
//TYPE
import { ModeAction, MessageType } from "state/enum";
import { API_SYSTEM_MASTER } from "shared/constants";
import { userProfile } from "constant";

const DEFAULT_VALUE = {
  userId: userProfile.userId,
  [API_SYSTEM_MASTER.DATA_OWNER]: userProfile.dataOwner,
  [API_SYSTEM_MASTER.CATEGORY]: "",
  [API_SYSTEM_MASTER.SUB_CATEGORY]: "",
  [API_SYSTEM_MASTER.CODE]: "",
  [API_SYSTEM_MASTER.VALUE]: "",
  [API_SYSTEM_MASTER.REMARK]: "",
  [API_SYSTEM_MASTER.STATUS]: "",
  [API_SYSTEM_MASTER.STATUS_ID]: "",
  [API_SYSTEM_MASTER.CREATE_BY]: "",
  [API_SYSTEM_MASTER.CREATE_DATE]: "",
  [API_SYSTEM_MASTER.UPDATE_BY]: "",
  [API_SYSTEM_MASTER.UPDATE_DATE]: "",
};
export default function SystemMasterScreen() {
  // Form
  const [form, setForm] = useState(DEFAULT_VALUE);
  const [msgError, setMsgError] = useState([]);
  const [msgAlert, setMsgAlert] = useState([]);
  const [onSearch, setOnSearch] = useState(false);
  const [mode, setMode] = useState(ModeAction.VIEW);

  // Table
  const [rows, setRows] = useState([]);
  const [pagination, setPagination] = useState({});
  const [pageNumber, setPageNumber] = useState(1);
  const [rowModesModel, setRowModesModel] = useState({});
  const [rowSelectionModel, setRowSelectionModel] = useState([]);

  // Button State
  //Action bar
  const [addBtn, setAddBtn] = useState(false);
  const [editBtn, setEditBtn] = useState(false);
  const [deleteBtn, setDeleteBtn] = useState(false);
  const [downloadBtn, setDownloadBtn] = useState(false);
  // button bar
  const [searchBtn, setSearchBtn] = useState(false);
  const [clearBtn, setClearBtn] = useState(false);

  //API
  const { data: categoryList } = useSystemMasterCategoryQuery();
  const { data: subCategoryList } = useSystemMasterSubCategoryQuery(form?.category ? form?.category : "category");
  const { data: statusList } = useSystemMasterStatusQuery();
  const systemMasterSearch = useSystemMasterSearchMutation();

  useEffect(() => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useButtonMode({
      mode,
      rows,
      setSearchBtn,
      setClearBtn,
      setAddBtn,
      setEditBtn,
      setDeleteBtn,
      setDownloadBtn,
    });
  }, [mode, rows]);
  const columns = useMemo(
    // eslint-disable-next-line react-hooks/rules-of-hooks
    () => useColumns({ rows, mode, statusList, form }),
    [rows, mode, statusList, form]
  );
  // get search
  const getSearch = async body => {
    try {
      const { isSuccess, errors } = validationSearchForm({
        data: body,
        rule: [
          {
            field: API_SYSTEM_MASTER.CATEGORY,
            type: MessageType.EMPTY,
            key: ["Category"],
          },
        ],
      });
      if (!isSuccess) {
        setMsgError(errors);
        window.scrollTo(0, 0);
        return;
      }
      const searchData = await systemMasterSearch(body);
      const data =
        searchData?.result?.data?.map((item, index) => ({
          no: index + 1,
          [API_SYSTEM_MASTER.ROW_NUMBER]: item?.rowNumber,
          [API_SYSTEM_MASTER.CATEGORY]: item?.category,
          [API_SYSTEM_MASTER.SUB_CATEGORY]: item?.subCategory,
          [API_SYSTEM_MASTER.CODE]: item?.code,
          [API_SYSTEM_MASTER.VALUE]: item?.value,
          [API_SYSTEM_MASTER.REMARK]: item?.remark,
          [API_SYSTEM_MASTER.STATUS]: item?.status,
          [API_SYSTEM_MASTER.STATUS_ID]: item?.statusId,
          [API_SYSTEM_MASTER.CREATE_BY]: item?.createBy,
          [API_SYSTEM_MASTER.CREATE_DATE]: item?.createDt,
          [API_SYSTEM_MASTER.UPDATE_DATE]: item?.updateDt,
          [API_SYSTEM_MASTER.UPDATE_BY]: item?.updateBy,
          [API_SYSTEM_MASTER.UPDATE_KEY]: item?.updateKey,
        })) ?? [];

      return { searchData, data };
    } catch (error) {
      console.error(error);
      const errors = responseErrors(error);
      setMsgError([`${errors}`]);
      return;
    }
  };

  return (
    <Fragment>
      <Box sx={{ padding: "1rem" }}>
        <HeaderContentBar title="WSTD3060 : System Master 2 levels" />

        <AlertMessage type={"warning"} message={msgError} />
        <AlertMessage type={"success"} message={msgAlert} />
        <Grid container spacing={2} sx={{ mt: 2, alignItems: "center" }}>
          <Grid item xs={10}>
            <FilterBar
              mode={mode}
              setMode={setMode}
              setRows={setRows}
              form={form}
              setForm={setForm}
              setOnSearch={setOnSearch}
              dataList={{
                categoryList: categoryList,
                subCategoryList: subCategoryList,
              }}
              setMsg={{ setMsgAlert, setMsgError }}
              pagination={{
                pagination: pagination,
                pageNumber: pageNumber,
                setPagination: setPagination,
                setPageNumber: setPageNumber,
              }}
              rowSelection={{ rowSelectionModel, rowModesModel, setRowSelectionModel, setRowModesModel }}
            />
          </Grid>
          <Grid item xs={12}>
            <ButtonBar
              form={form}
              setForm={setForm}
              setOnSearch={setOnSearch}
              getSearch={getSearch}
              setMsg={{ setMsgAlert, setMsgError }}
              stateBtn={{ searchBtn, clearBtn }}
              pagination={{
                pagination: pagination,
                pageNumber: pageNumber,
                setPagination: setPagination,
                setPageNumber: setPageNumber,
              }}
              setRows={setRows}
              setMode={setMode}
              setRowSelectionModel={setRowSelectionModel}
              setRowModesModel={setRowModesModel}
              functionId={"WSTD3060"}
            />
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid item xs={12}>
            <ActionTableBar
              setMode={setMode}
              rows={rows}
              setRows={setRows}
              columns={columns}
              form={form}
              setForm={setForm}
              onSearch={onSearch}
              setOnSearch={setOnSearch}
              getSearch={getSearch}
              pagination={{ pageNumber, setPageNumber, setPagination }}
              rowSelection={{ rowSelectionModel, rowModesModel, setRowSelectionModel, setRowModesModel }}
              stateBtn={{ addBtn, editBtn, deleteBtn, downloadBtn }}
              setMsg={{ setMsgAlert, setMsgError }}
              functionId={"WSTD3060"}
            />
          </Grid>
          {onSearch && (
            <Grid item xs={12}>
              <TableBar
                form={form}
                setForm={setForm}
                mode={mode}
                setMode={setMode}
                onSearch={onSearch}
                rows={rows}
                setRows={setRows}
                columns={columns}
                setPagination={setPagination}
                setPageNumber={setPageNumber}
                setRowModesModel={setRowModesModel}
                setMsg={{ setMsgAlert, setMsgError }}
                rowModesModel={rowModesModel}
                rowSelectionModel={rowSelectionModel}
                setRowSelectionModel={setRowSelectionModel}
                pagination={pagination}
                pageNumber={pageNumber}
                getSearch={getSearch}
                setOnSearch={setOnSearch}
                functionId={"WSTD3060"}
              />
            </Grid>
          )}
        </Grid>
      </Box>
    </Fragment>
  );
}
