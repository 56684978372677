import { Grid } from "@mui/material";
import { DatePickerField, DropDown, InputTextField } from "components/UI";
import React, { Fragment } from "react";
import { ModeAction } from "state/enum";

export default function CriterialPrint({
  form,
  setForm,
  setRows,
  setMode,
  setOnSearch,
  setRowSelectionModel,
  dataList: {
    deliveryTypeList,
    packStatusList,
    senderList,
    receiverList,
    shippingInfoList,
  },
}) {
  const handleDvlType = (e) => {
    try {
      setForm((prev) => ({
        ...prev,
        deliveryType: e.target.value,
        sender: "",
        receiver: "",
      }));
      setMode(ModeAction.VIEW);
      setRowSelectionModel([]);
      setRows([]);
      setOnSearch(false);
      return;
    } catch (error) {
      console.error(error);
    }
  };
  return (
    <Fragment>
      <Grid container spacing={2} sx={{ alignItems: "center" }}>
        {/* Actual Delivery Date */}
        <Grid item xs={2}>
          <strong>*Actual Delivery Date (From) :</strong>
        </Grid>
        <Grid item xs={3}>
          <DatePickerField
            id="date-picker-actual-from"
            required={true}
            fullWidth={true}
            autoFocus={true}
            value={form?.actualDtFrom}
            onChange={(date) =>
              setForm((prev) => ({ ...prev, actualDtFrom: date }))
            }
          />
        </Grid>
        <Grid item xs={1} />
        <Grid item xs={2}>
          <strong>Actual Delivery Date (To) :</strong>
        </Grid>
        <Grid item xs={3}>
          <DatePickerField
            id="date-picker-actual-to"
            fullWidth={true}
            value={form?.actualDtTo}
            onChange={(date) =>
              setForm((prev) => ({ ...prev, actualDtTo: date }))
            }
          />
        </Grid>
        <Grid item xs={1} />
        {/* Line 2 */}
        <Grid item xs={2}>
          <strong>*Delivery Type :</strong>
        </Grid>
        <Grid item xs={3}>
          <DropDown
            id="combobox-delivery-type"
            required={true}
            defaultValue=""
            placeholder="<Select>"
            value={form?.deliveryType}
            onChange={handleDvlType}
            menu={deliveryTypeList?.map((val) => ({
              key: val?.cd,
              value: val?.value,
            }))}
          />
        </Grid>
      </Grid>
      {/* Line 3 */}
      <Grid container spacing={1} sx={{ mt: 2, alignItems: "center" }}>
        <Grid item xs={2}>
          <strong>Sender :</strong>
        </Grid>
        <Grid item xs={3}>
          <DropDown
            id="combobox-sender"
            placeholder="<All>"
            defaultValue=""
            value={form?.sender}
            onChange={(e) =>
              setForm((prev) => ({ ...prev, sender: e.target.value }))
            }
            menu={senderList?.map((val) => ({
              key: val?.senderId,
              value: val?.senderName,
            }))}
          />
        </Grid>
        <Grid item xs={1} />
        <Grid item xs={2}>
          <strong>Receiver :</strong>
        </Grid>
        <Grid item xs={3}>
          <DropDown
            id="combobox-receiver"
            placeholder="<All>"
            defaultValue=""
            value={form?.receiver}
            onChange={(e) =>
              setForm((prev) => ({ ...prev, receiver: e.target.value }))
            }
            menu={receiverList?.map((val) => ({
              key: val?.receiverId,
              value: val?.receiverName,
            }))}
          />
        </Grid>
        {/* Line 4 */}
        <Grid item xs={1} />
        <Grid item xs={2}>
          <strong>Shipping Information :</strong>
        </Grid>
        <Grid item xs={3}>
          <DropDown
            id="combobox-shipping-info"
            placeholder="<All>"
            defaultValue=""
            value={form?.shippingInfo}
            onChange={(e) =>
              setForm((prev) => ({ ...prev, shippingInfo: e.target.value }))
            }
            menu={shippingInfoList?.map((val, index) => ({
              key: index,
              value: `${val?.shippingCompany}:${val?.shippingDate}`,
            }))}
          />
        </Grid>
        <Grid item xs={1} />
        <Grid item xs={2}>
          <strong>Container No. :</strong>
        </Grid>
        <Grid item xs={3}>
          <InputTextField
            id="text-field-container-no"
            fullWidth
            value={form?.containerNo}
            onChange={(e) =>
              setForm((prev) => ({
                ...prev,
                containerNo: e.target.value?.toUpperCase(),
              }))
            }
            maxLength={15}
            regularExp={/^[a-zA-Z0-9]*$/}
          />
        </Grid>
        {/* Line 4 */}
        <Grid item xs={1} />
        <Grid item xs={2}>
          <strong>Delivery Instruction Sheet No. :</strong>
        </Grid>

        <Grid item xs={3}>
          <InputTextField
            id="text-field-delivery-instr-sht-no"
            fullWidth
            value={form?.deliveryInstrSheetNo}
            onChange={(e) =>
              setForm((prev) => ({
                ...prev,
                deliveryInstrSheetNo: e.target.value?.toUpperCase(),
              }))
            }
            maxLength={20}
            regularExp={/^[a-zA-Z0-9]*$/}
          />
        </Grid>
        <Grid item xs={1} />
        <Grid item xs={2}>
          <strong>Pack Status :</strong>
        </Grid>
        <Grid item xs={3}>
          <DropDown
            id="combobox-pack-status"
            placeholder="<All>"
            defaultValue=""
            value={form?.packStatus}
            onChange={(e) =>
              setForm((prev) => ({ ...prev, packStatus: e.target.value }))
            }
            menu={packStatusList?.map((val) => ({
              key: val?.cd,
              value: val?.value,
            }))}
          />
        </Grid>
      </Grid>
    </Fragment>
  );
}
