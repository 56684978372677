import React, { useMemo } from "react";
import { Grid } from "@mui/material";
import { DataGrid, GridRowEditStopReasons } from "@mui/x-data-grid";
import { ModeAction } from "state/enum";
import PaginationTable from "./Pagination";

const DataTable = ({
  mode,
  onSearch,
  rowSelectionModel,
  setRowSelectionModel,
  setMode,
  rows,
  rowModesModel,
  columns,
  setRowModesModel,
  processRowUpdate,
  pagination,
  pageNumber,
  handleChangePagination,
  columnGroupingModel,
  columnVisibilityModel,
  rowHeight = 60,
  rowHeightAuto = true,
  checkboxSelection = true,
  props,
  isRowSelectable = undefined,
  onRowSelectionModelChange = undefined,
  columnHeaderHeight = 40,
  enabledPagination = true,
}) => {
  useMemo(() => onSearch, [onSearch]);

  const handleRowSelectionModelChange = newRowSelectionModel => {
    if (newRowSelectionModel?.length) {
      setMode(ModeAction.SELECTED);
    } else {
      setMode(ModeAction.VIEW);
    }
    setRowSelectionModel(newRowSelectionModel);
  };

  const handleRowModesModelChange = newRowModesModel => {
    setRowModesModel(newRowModesModel);
  };

  const handleRowEditStop = (params, event) => {
    event.defaultMuiPrevented = true;

    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
    if (params.reason === GridRowEditStopReasons.enterKeyDown) {
      event.defaultMuiPrevented = true;
    }
    if (params.reason === GridRowEditStopReasons.escapeKeyDown) {
      event.defaultMuiPrevented = true;
    }
    if (params.reason === GridRowEditStopReasons.tabKeyDown) {
      event.defaultMuiPrevented = true;
    }
    if (params.reason === GridRowEditStopReasons.shiftTabKeyDown) {
      event.defaultMuiPrevented = true;
    }
  };

  const halfColumnHeaderHeight = columnHeaderHeight / 2;

  return (
    <>
      {onSearch && rows?.length > 0 && (
        <Grid container spacing={3} >
          <Grid item xs={12} id="ele_table" sx={{ height: "auto", width: "100%" }}>
            <DataGrid
              sx={{
                "& .MuiDataGrid-columnHeaderTitleContainer.MuiDataGrid-withBorderColor": {
                  border: "none",
                },
                "& .MuiDataGrid-columnHeaderTitle": {
                  whiteSpace: "wrap",
                  lineHeight: "20px",
                  textAlign: "center",
                },
                "& .MuiDataGrid-columnHeaderTitleContainerContent": {
                  whiteSpace: "wrap",
                  lineHeight: "20px",
                  textAlign: "center",
                },
                "& .MuiDataGrid-cell.MuiDataGrid-withBorderColor": {
                  border: "0.5px solid #cecece",
                  outline: "none",
                },
                "& .MuiDataGrid-row:last-child": {
                  borderBottom: "1px solid #cecece",
                },
                "& .MuiDataGrid-cell.MuiDataGrid-cell--editing:focus-within": {
                  outline: "none",
                },
                "& .MuiDataGrid-columnSeparator": {
                  display: "none",
                },
                "&.MuiDataGrid-root": {
                  border: "none",
                },
                "& .MuiDataGrid-columnHeader": {
                  borderLeft: "1px solid #ffff",
                  borderTop: "none",
                },
                "& .MuiDataGrid-columnHeaders": {
                  "& .MuiDataGrid-withBorderColor:focus": {
                    outline: "none",
                  },
                  background: "#7F7F7F",
                  color: "#ffff",
                },
                "& .MuiDataGrid-virtualScroller": {
                  minHeight: "300px",
                },
                "& .Mui-odd": {
                  background: "#e3e3e3",
                },

                "& .MuiDataGrid-columnHeaderCheckbox .MuiDataGrid-columnHeaderTitleContainer": {
                  display: "none",
                },
                "& .align-items-end .MuiDataGrid-columnHeaderDraggableContainer": {
                  alignItems: "center",
                  transform: `translateY(${halfColumnHeaderHeight}px)`,
                },
              }}
              getRowClassName={params => (params.indexRelativeToCurrentPage % 2 === 0 ? "Mui-even" : "Mui-odd")}
              rowHeight={rowHeight}
              checkboxSelection={checkboxSelection}
              disableRowSelectionOnClick
              autoHeight
              onRowSelectionModelChange={onRowSelectionModelChange || handleRowSelectionModelChange}
              isRowSelectable={params => {
                if (isRowSelectable !== undefined) {
                  return isRowSelectable;
                }

                if (mode === ModeAction.SELECTED) {
                  return params.row.rowNumber !== rowSelectionModel[0] ? false : true;
                }

                if (mode === ModeAction.VIEW) {
                  return true;
                } else {
                  return false;
                }
              }}
              rowSelectionModel={rowSelectionModel}
              getRowId={row => row?.rowNumber} // TODO: replace real id
              experimentalFeatures={{ columnGrouping: true }}
              columnGroupingModel={columnGroupingModel}
              editMode="row"
              rows={rows}
              rowModesModel={rowModesModel}
              columns={columns}
              onRowModesModelChange={handleRowModesModelChange}
              onRowEditStop={handleRowEditStop}
              onCellKeyDown={(params, event) => {
                event.defaultMuiPrevented = true;
              }}
              processRowUpdate={processRowUpdate}
              onCellDoubleClick={(params, event) => {
                event.stopPropagation();
              }}
              onProcessRowUpdateError={error => {
                console.log("onProcessRowUpdateError", error);
              }}
              hideFooter
              disableColumnMenu
              columnVisibilityModel={columnVisibilityModel}
              onCellEditStart={params => {
                console.log("onCellEditStart", params);
              }}
              onCellEditStop={params => console.log("onCellEditStop", params)}
              getRowHeight={() => (rowHeightAuto ? "auto" : rowHeight)}
              columnHeaderHeight={columnHeaderHeight}
              {...props}
            />
          </Grid>
          <Grid item xs={12} sx={{ display: "flex", justifyContent: "space-between", py: 2 }}>
            {enabledPagination && (
              <PaginationTable
                pagination={pagination}
                pageNumber={pageNumber}
                handleChangePagination={handleChangePagination}
                mode={mode}
              />
            )}
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default DataTable;
