import axiosInstance, { AxiosError } from "axios";

const axios = axiosInstance.create({
  baseURL: process.env.REACT_APP_API_URL,
  withCredentials: false,
  // TODO: timeout
  timeout: 2 * 60 * 1000, // 60000 = 1 minute
  timeoutErrorMessage: "Axios TIMEOUT",
});
// Request interceptor
axios.interceptors.request.use(
  response => {
    return Promise.resolve(response);
  },
  error => {
    // Handle request error
    return Promise.reject(error);
  }
  // async error => {
  //   if ([401, 403].includes(error?.response?.status)) {
  //     return Promise.reject(error?.response);
  //   } else if (error.response.status === 503) {
  //     return Promise.reject(error?.response);
  //   } else if (error.response.status === 408 || error.code === 'ECONNABORTED') {
  //     return Promise.reject(error?.response);
  //   } else if (axios.isCancel(error)) {
  //     return Promise.reject(error?.response);
  //   }
  //   return Promise.reject(error?.response);
  // }
);

// Response interceptor
axios.interceptors.response.use(
  response => {
    // return response;
    return Promise.resolve(response);
  },
  (error = AxiosError) => {
    switch (error.response?.status) {
      case 400:
        // console.log('---errr', error.response);
        return Promise.reject(error);
      case 401:
        // alert(`Error: ${error.response.status} | Unauthorized access. Please log in.`);
        return Promise.reject(error);

      case 500:
        return Promise.reject(error.response.data);
      case 503:
        return Promise.reject({
          response: {
            statusCode: 503,
            data: {
              errors: [
                {
                  code: "MSTD0067AERR",
                  message: `Undefine Error : Internal server error (Service not available). Please try again later.`,
                },
              ],
            },
          },
        });
      // alert(`Error: ${error.response.status} | Internal server error. Please try again later.`);
      case 504:
        console.error(error);
        // alert(`Error: ${error.response.status} | Internal server error. Please try again later.`);
        return Promise.reject({
          response: {
            statusCode: 503,
            data: {
              errors: [
                {
                  code: "MSTD0067AERR",
                  message: `Undefine Error : Internal server error (Connection Timeout). Please try again later.`,
                },
              ],
            },
          },
        });
      // break;
      default:
        console.error(error);

        if (!error.response) {
          const err = {
            response: {
              statusCode: 504,
              data: {
                errors: [
                  {
                    code: "MSTD0067AERR",
                    message: `Undefine Error : Internal server error. Please try again later.`,
                  },
                ],
              },
            },
          };
          return Promise.reject(err);
        }
        return Promise.reject(error);
      // break;
    }

    // Handle response error
    // return Promise.reject(error.response?.status);
  }
);

export default axios;
