import React, { Fragment } from "react";
import { Typography } from "components/UI";

import { Checkbox, FormControlLabel } from "@mui/material";
export default function CheckBox({ id, placement, label, onChange, checked = false, ...props }) {
  return (
    <Fragment>
      <FormControlLabel
        {...props}
        value={placement}
        labelPlacement={placement}
        label={
          <Typography
            content={label}
            sx={{
              color: "#000",
              fontFamily: "Kanit",
              fontWeight: "bold",
            }}
            noWrap={false}
          />
        }
        onChange={onChange}
        control={<Checkbox data-testid={id} id={id} size="medium" checked={checked} {...props} />}
      />
    </Fragment>
  );
}
