import { Grid } from "@mui/material";
import { DropDown, InputTextField } from "components/UI";
import React, { useEffect, useMemo, useState } from "react";
import ButtonAction from "../ButtonAction";
import { FORM_DEFAULT_AGING_STOCK } from "pages/Report/constants/constants";
import {
  useReportCommonGetRPackageOwnerQuery,
  useReportCommonGetRPackageTypeQuery,
  useReportCommonGetLocationNameListQuery,
  useReportGetAvailableRPackageStatusQuery,
  useReportGetAvailablePackStatusQuery,
  useReportGetDefaultMaximumAgingDayQuery,
  useReportGetDefaultTargetLineForWIP2EmptyQuery,
  useReportGetDefaultTargetLineForFGEmptyQuery,
  useReportGetDefaultTargetLineForFGPackQuery,
  useReportGetDefaultTargetLineForNGEmptyQuery,
  useReportGetDefaultTargetLineForNGPackQuery,
  useReportGetDefaultTargetLineForWIP1EmptyQuery,
  useExcelDownloadOnlineMutation,
  useExcelDownloadOnlineJavaMutation,
} from "shared/services/report";
import { userProfile } from "constant";
import { responseDownloadFileErrors, responseErrors, responseSuccess } from "utils";
import InputCurrency from "components/UI/InputCurrency";
import axios from "axios";
import { getLocalDate } from "utils/init-config-date";
import { useLoadingContext } from "shared/contexts/LoadingContext";
import { ResponseText } from "utils/5xxResponseText";
export default function AgingStockReport(props) {
  const [form, setForm] = useState(FORM_DEFAULT_AGING_STOCK);
  const { startLoading, stopLoading } = useLoadingContext();
  //API
  const { data: rPkgOwnerList, failureReason: rPkgOwnerFailureReason } = useReportCommonGetRPackageOwnerQuery();
  const { data: rPkgTypeList, failureReason: rPkgTypeFailureReason } = useReportCommonGetRPackageTypeQuery({
    rPackageOwner: userProfile.dataOwner,
  });
  const { data: locationFromToList, failureReason: locationFromToFailureReason } =
    useReportCommonGetLocationNameListQuery({
      dataOwner: userProfile.dataOwner,
    });
  const { data: rPackageStatusList, failureReason: rPackageStatusFailureReason } =
    useReportGetAvailableRPackageStatusQuery();
  const { data: packStatusList, failureReason: packStatusFailureReason } = useReportGetAvailablePackStatusQuery();
  const { data: maximumAgingDayList, failureReason: maximumAgingDayFailureReason } =
    useReportGetDefaultMaximumAgingDayQuery();
  const { data: wIP1EmptyList, failureReason: wIP1EmptyFailureReason } =
    useReportGetDefaultTargetLineForWIP1EmptyQuery();
  const { data: wIP2EmptyList, failureReason: wIP2EmptyFailureReason } =
    useReportGetDefaultTargetLineForWIP2EmptyQuery();
  const { data: fgEmptyList, failureReason: fgEmptyFailureReason } = useReportGetDefaultTargetLineForFGEmptyQuery();
  const { data: fgPackList, failureReason: fgPackFailureReason } = useReportGetDefaultTargetLineForFGPackQuery();
  const { data: ngEmptyList, failureReason: ngEmptyFailureReason } = useReportGetDefaultTargetLineForNGEmptyQuery();
  const { data: ngPackList, failureReason: ngPackFailureReason } = useReportGetDefaultTargetLineForNGPackQuery();

  const generateReport = useExcelDownloadOnlineJavaMutation();
  // const generateReport = useExcelDownloadOnlineMutation();

  useEffect(() => {
    let message = [];
    if (rPkgOwnerFailureReason?.response?.status >= 404) {
      message.push(`R-Package Owner List: ${ResponseText[rPkgOwnerFailureReason?.response?.status]}`);
    }
    if (rPkgTypeFailureReason?.response?.status >= 404) {
      message.push(`R-Package Type List: ${ResponseText[rPkgTypeFailureReason?.response?.status]}`);
    }
    if (locationFromToFailureReason?.response?.status >= 404) {
      message.push(`Location From - To List: ${ResponseText[locationFromToFailureReason?.response?.status]}`);
    }
    if (rPackageStatusFailureReason?.response?.status >= 404) {
      message.push(`R-Package Status List: ${ResponseText[rPackageStatusFailureReason?.response?.status]}`);
    }
    if (packStatusFailureReason?.response?.status >= 404) {
      message.push(`Pack Status List: ${ResponseText[packStatusFailureReason?.response?.status]}`);
    }
    if (maximumAgingDayFailureReason?.response?.status >= 404) {
      message.push(`Maximum Aging Day: ${ResponseText[maximumAgingDayFailureReason?.response?.status]}`);
    }
    if (wIP1EmptyFailureReason?.response?.status >= 404) {
      message.push(`WIP1 - EMPTY : ${ResponseText[wIP1EmptyFailureReason?.response?.status]}`);
    }
    if (wIP2EmptyFailureReason?.response?.status >= 404) {
      message.push(`WIP2 - EMPTY : ${ResponseText[wIP2EmptyFailureReason?.response?.status]}`);
    }
    if (fgEmptyFailureReason?.response?.status >= 404) {
      message.push(`FG - EMPTY : ${ResponseText[fgEmptyFailureReason?.response?.status]}`);
    }
    if (fgPackFailureReason?.response?.status >= 404) {
      message.push(`FG - PACK : ${ResponseText[fgPackFailureReason?.response?.status]}`);
    }
    if (ngEmptyFailureReason?.response?.status >= 404) {
      message.push(`NG - EMPTY : ${ResponseText[ngEmptyFailureReason?.response?.status]}`);
    }
    if (ngPackFailureReason?.response?.status >= 404) {
      message.push(`NG - PACK : ${ResponseText[ngPackFailureReason?.response?.status]}`);
    }
    props.setMsgServerErrorAlert(message);
  }, [
    rPkgOwnerFailureReason?.response?.status,
    rPkgTypeFailureReason?.response?.status,
    locationFromToFailureReason?.response?.status,
    rPackageStatusFailureReason?.response?.status,
    packStatusFailureReason?.response?.status,
    maximumAgingDayFailureReason?.response?.status,
    wIP1EmptyFailureReason?.response?.status,
    wIP2EmptyFailureReason?.response?.status,
    fgEmptyFailureReason?.response?.status,
    fgPackFailureReason?.response?.status,
    ngEmptyFailureReason?.response?.status,
    ngPackFailureReason?.response?.status,
    props,
  ]);

  const [disabledSubmitBtn, setDisabledSubmitBtn] = useState(false);

  const handleDownload = async () => {
    try {
      props.setMsgError([]);
      props.setMsgAlert([]);
      const body = {
        userId: userProfile.userId,
        displayName: userProfile.displayName,
        dataOwner: userProfile.dataOwner,
        locationName: form?.location,
        rPackageOwner: form?.rPkgTypeOwner,
        rPackageType: form?.rPkgType,
        maxAgingDay: parseInt(determineValue(form?.maximumAgingDay, maximumAgingDayList)),
        wip1Empty: parseInt(determineValue(form?.wip1Empty, wIP1EmptyList)),
        wip2Empty: parseInt(determineValue(form?.wip2Empty, wIP2EmptyList)),
        fgEmpty: parseInt(determineValue(form?.fgEmpty, fgEmptyList)),
        fgPack: parseInt(determineValue(form?.fgPack, fgPackList)),
        ngEmpty: parseInt(determineValue(form?.ngEmpty, ngEmptyList)),
        ngPack: parseInt(determineValue(form?.ngPack, ngPackList)),
        total: parseInt(form?.total),
      };
      const pathReport = "generate-aging-stock-report";
      // const fileName = `Aging_Stock_${getLocalDate().format("YYYYMMDDHHmm")}.xlsx`;
      startLoading();
      const response = await generateReport(body, pathReport);
      stopLoading();
      setDisabledSubmitBtn(false);
      return;
    } catch (error) {
      if (error?.response?.status >= 500) {
        props.setMsgServerErrorAlert([ResponseText[error?.response?.status]]);
      } else {
        const msgError = responseDownloadFileErrors(error);
        props.setMsgError(msgError);
      }
      stopLoading();
      setDisabledSubmitBtn(false);
    }
  };

  useEffect(() => {
    document.getElementById("select-location").focus();
  }, []);

  const determineValue = (formValue, initValue) => {
    // First, check if form?.maximumAgingDay has a length and return it if true
    if (formValue?.length) {
      return formValue;
    }
    if (initValue?.length) {
      return initValue[0]?.value;
    }
  };

  useEffect(() => {
    // Convert all values to numbers and sum them up
    const total =
      Number(determineValue(form.wip1Empty, wIP1EmptyList)) +
      Number(determineValue(form.wip2Empty, wIP2EmptyList)) +
      Number(determineValue(form.fgEmpty, fgEmptyList)) +
      Number(determineValue(form.fgPack, fgPackList)) +
      Number(determineValue(form.ngEmpty, ngEmptyList)) +
      Number(determineValue(form.ngPack, ngPackList));
    return setForm(old => ({
      ...old,
      total: total,
    }));
  }, [
    form.wip1Empty,
    form.wip2Empty,
    form.fgEmpty,
    form.fgPack,
    form.ngEmpty,
    form.ngPack,
    wIP1EmptyList,
    wIP2EmptyList,
    fgEmptyList,
    fgPackList,
    ngEmptyList,
    ngPackList,
  ]);

  return (
    <Grid container spacing={2}>
      {/* Container item #1 */}
      <Grid container spacing={1} sx={{ alignItems: "center", mb: 2 }}>
        <Grid item xs={2}>
          <strong>*Report : </strong>
        </Grid>
        <Grid item xs={3}>
          <DropDown
            id="select-report"
            fullWidth
            required
            defaultValue=""
            placeholder="<Select>"
            menu={[{ key: "LDN960H0", value: "Aging Stock Report" }]}
            value={"LDN960H0"}
            disabled
          />
        </Grid>
      </Grid>

      {/* Container item #2 */}
      <Grid container spacing={1} sx={{ alignItems: "center", mb: 2 }}>
        <Grid item xs={2}>
          <strong>*Location : </strong>
        </Grid>
        <Grid item xs={3}>
          <DropDown
            id="select-location"
            fullWidth
            required
            value={form?.location}
            defaultValue=""
            placeholder="<Select>"
            // autoFocus={true}
            menu={locationFromToList?.map(val => ({
              key: val?.name,
              value: val?.name,
            }))}
            onChange={e =>
              setForm(old => ({
                ...old,
                location: e.target.value,
              }))
            }
          />
        </Grid>
        <Grid item xs={1} />
        <Grid item xs={2}>
          <strong>R-Package Owner : </strong>
        </Grid>
        <Grid item xs={3}>
          <DropDown
            id="select-rpackage-owner"
            fullWidth
            value={form?.rPkgTypeOwner}
            defaultValue=""
            placeholder="<All>"
            // autoFocus={true}
            menu={rPkgOwnerList?.map(val => ({
              key: val?.rPkgOwnerCompAbbr,
              value: val?.rPkgOwnerCompAbbr,
            }))}
            onChange={e =>
              setForm(old => ({
                ...old,
                rPkgTypeOwner: e.target.value,
              }))
            }
          />
        </Grid>
      </Grid>

      {/* Container item #3 */}
      <Grid container spacing={1} sx={{ alignItems: "center", mb: 1 }}>
        <Grid item xs={2}>
          <strong>R-Package Type : </strong>
        </Grid>
        <Grid item xs={3}>
          <DropDown
            id="select-rpackage-type"
            fullWidth
            value={form?.rPkgType}
            defaultValue=""
            placeholder="<All>"
            // autoFocus={true}
            menu={rPkgTypeList?.map(val => ({
              key: val?.rPkgType,
              value: val?.rPkgType,
            }))}
            onChange={e =>
              setForm(old => ({
                ...old,
                rPkgType: e.target.value,
              }))
            }
            disabled={!form?.rPkgTypeOwner.length}
          />
        </Grid>
        <Grid item xs={1} />
        <Grid item xs={2}>
          <strong>R-Package Status : </strong>
        </Grid>
        <Grid item xs={3}>
          <DropDown
            id="select-rpackage-status"
            fullWidth
            value={form?.rPkgStatus}
            defaultValue=""
            placeholder="<All>"
            // autoFocus={true}
            menu={rPackageStatusList?.map(val => ({
              key: val?.cd,
              value: val?.value,
            }))}
            onChange={e =>
              setForm(old => ({
                ...old,
                rPkgStatus: e.target.value,
              }))
            }
          />
        </Grid>
      </Grid>

      {/* Container item #4 */}
      <Grid container spacing={1} sx={{ alignItems: "center", mb: 2 }}>
        <Grid item xs={2}>
          <strong>Pack Status : </strong>
        </Grid>
        <Grid item xs={3}>
          <DropDown
            id="select-pack-status"
            fullWidth
            value={form?.packStatus}
            defaultValue=""
            placeholder="<All>"
            // autoFocus={true}
            menu={packStatusList?.map(val => ({
              key: val?.cd,
              value: val?.value,
            }))}
            onChange={e =>
              setForm(old => ({
                ...old,
                packStatus: e.target.value,
              }))
            }
          />
        </Grid>
        <Grid item xs={1} />
        <Grid item xs={2}>
          <strong>Maximum Aging Day : </strong>
        </Grid>
        <Grid item xs={3}>
          <InputTextField
            id="input-maximum-aging-day"
            fullWidth={true}
            maxLength={3}
            value={determineValue(form?.maximumAgingDay, maximumAgingDayList)}
            regularExp={/^[\d.]*$/}
            onChange={e =>
              setForm(old => ({
                ...old,
                maximumAgingDay: e.target.value,
              }))
            }
          />
        </Grid>
      </Grid>

      {/* Container item #5 */}
      <Grid container spacing={1} sx={{ alignItems: "center", mb: 2 }}>
        <Grid item xs={2}>
          <strong>Target Line (Day) For : </strong>
        </Grid>
      </Grid>

      {/* Container item #6 */}
      <Grid container spacing={1} sx={{ alignItems: "center", mb: 1 }}>
        <Grid item xs={2}>
          <strong>WIP1 - EMPTY : </strong>
        </Grid>
        <Grid item xs={3}>
          <InputCurrency
            style={{ background: "white" }}
            id="input-wip1-empty"
            fullWidth={true}
            maxLimit={999999}
            decimalScale={2}
            value={determineValue(form?.wip1Empty, wIP1EmptyList)}
            // regularExp={/^[a-zA-Z0-9_*-]*$/}
            onChange={e =>
              setForm(old => ({
                ...old,
                wip1Empty: e.value,
              }))
            }
          />
        </Grid>
        <Grid item xs={1} />
        <Grid item xs={2}>
          <strong>WIP2 - EMPTY : </strong>
        </Grid>
        <Grid item xs={3}>
          <InputCurrency
            style={{ background: "white" }}
            id="input-wip2-empty"
            fullWidth={true}
            maxLimit={999999}
            decimalScale={2}
            value={determineValue(form?.wip2Empty, wIP2EmptyList)}
            // regularExp={/^[a-zA-Z0-9_*-]*$/}
            onChange={e =>
              setForm(old => ({
                ...old,
                wip2Empty: e.value,
              }))
            }
          />
        </Grid>
      </Grid>

      {/* Container item #7 */}
      <Grid container spacing={1} sx={{ alignItems: "center", mb: 1 }}>
        <Grid item xs={2}>
          <strong>FG - EMPTY : </strong>
        </Grid>
        <Grid item xs={3}>
          <InputCurrency
            style={{ background: "white" }}
            id="input-fg-empty"
            fullWidth={true}
            maxLimit={999999}
            decimalScale={2}
            value={determineValue(form?.fgEmpty, fgEmptyList)}
            // regularExp={/^[a-zA-Z0-9_*-]*$/}
            onChange={e =>
              setForm(old => ({
                ...old,
                fgEmpty: e.value,
              }))
            }
          />
        </Grid>
        <Grid item xs={1} />
        <Grid item xs={2}>
          <strong>FG - PACK : </strong>
        </Grid>
        <Grid item xs={3}>
          <InputCurrency
            style={{ background: "white" }}
            id="input-fg-pack"
            fullWidth={true}
            maxLimit={999999}
            decimalScale={2}
            value={determineValue(form?.fgPack, fgPackList)}
            // regularExp={/^[a-zA-Z0-9_*-]*$/}
            onChange={e =>
              setForm(old => ({
                ...old,
                fgPack: e.value,
              }))
            }
          />
        </Grid>
      </Grid>

      {/* Container item #8 */}
      <Grid container spacing={1} sx={{ alignItems: "center", mb: 1 }}>
        <Grid item xs={2}>
          <strong>NG - EMPTY : </strong>
        </Grid>
        <Grid item xs={3}>
          <InputCurrency
            style={{ background: "white" }}
            id="input-ng-empty"
            fullWidth={true}
            maxLimit={999999}
            decimalScale={2}
            value={determineValue(form?.ngEmpty, ngEmptyList)}
            // regularExp={/^[a-zA-Z0-9_*-]*$/}
            onChange={e =>
              setForm(old => ({
                ...old,
                ngEmpty: e.value,
              }))
            }
          />
        </Grid>
        <Grid item xs={1} />
        <Grid item xs={2}>
          <strong>NG - PACK : </strong>
        </Grid>
        <Grid item xs={3}>
          <InputCurrency
            style={{ background: "white" }}
            id="input-ng-pack"
            fullWidth={true}
            maxLimit={999999}
            decimalScale={2}
            value={determineValue(form?.ngPack, ngPackList)}
            // regularExp={/^[a-zA-Z0-9_*-]*$/}
            onChange={e =>
              setForm(old => ({
                ...old,
                ngPack: e.value,
              }))
            }
          />
        </Grid>
      </Grid>

      {/* Container item #9 */}
      <Grid container spacing={1} sx={{ alignItems: "center", mb: 1 }}>
        <Grid item xs={2}>
          <strong>Total : </strong>
        </Grid>
        <Grid item xs={3}>
          <InputCurrency
            style={{ background: "white" }}
            id="input-total"
            fullWidth={true}
            maxLimit={999999}
            decimalScale={2}
            value={form?.total ?? 0}
            onChange={e =>
              setForm(old => ({
                ...old,
                total: e.value,
              }))
            }
          />
        </Grid>
      </Grid>

      <ButtonAction
        handleDownload={handleDownload}
        setFrom={setForm}
        defaultFrom={FORM_DEFAULT_AGING_STOCK}
        disabled={disabledSubmitBtn}
        setDisabled={setDisabledSubmitBtn}
        clearMsg={() => {
          props.setMsgAlert([]);
          props.setMsgError([]);
        }}
        firstField={"select-location"}
      />
    </Grid>
  );
}
