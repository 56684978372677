import React from "react";
import { useGridApiContext } from "@mui/x-data-grid";
import { InputText } from ".";

const InputEditTableText = ({
  value,
  field,
  id,
  disabled = false,
  setValue = undefined,
  className,
  required,
  style,
  decimal = false,
  digits = 2,
  type = "text",
  maxLength,
  regularExp,
  fullWidth = false,
  upperCase = false,
  autoFocus,
}) => {
  const apiRef = useGridApiContext();

  const handleValueChange = (event) => {
    const newValue = event.target.value; // Trim the new value

    // decimal mode
    const onlyContainsNumbers = /^[\d.]*$/.test(newValue);
    const decimalPartLength = newValue?.split(".")[1]?.length || 0;
    if (
      decimal &&
      newValue !== "" &&
      (decimalPartLength > digits || !onlyContainsNumbers)
    ) {
      return;
    }

    if (regularExp && !regularExp.test(newValue)) {
      return;
    }

    apiRef.current.setEditCellValue({ id, field, value: newValue });
    if (setValue) setValue(newValue, id);
  };

  const handleBlur = (e) => {
    try {
      const trimmedValue = e.target.value.trim();
      if (trimmedValue) {
        // Create a new event with the trimmed value
        const newEvent = {
          ...e,
          target: { ...e.target, value: trimmedValue },
        };
        handleValueChange(newEvent);
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div style={{ margin: "0 5px", display: "grid", width: "100%" }}>
      <InputText
        autoFocus={autoFocus}
        type={type}
        value={upperCase ? value?.toUpperCase() : value}
        onChange={handleValueChange}
        disabled={disabled}
        className={className}
        required={required}
        maxLength={maxLength}
        style={{ height: "30px", ...style }}
        fullWidth={fullWidth}
        regularExp={!regularExp && /^[a-zA-Z0-9]*$/}
        onBlur={handleBlur}
      />
    </div>
  );
};

export default InputEditTableText;
