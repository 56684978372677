import React, { Fragment } from "react";
import { Grid } from "@mui/material";
// SHARED COMPONENTS
import { DropDown, InputMask } from "components/UI";
// Type
import { ModeAction } from "state/enum";
import { API_NAME_APMC } from "shared/constants";
import TemplateBar from "./TemplateBar";

export default function FilterBar({
  form,
  setForm,
  mode,
  setMode,
  setMsg,
  dataList: { modeList, dataTypeList, uploadStatusList },
  ...rest
}) {
  return (
    <Fragment>
      <Grid container spacing={2} sx={{ mb: 4 }}>
        <Grid item xs={2} sx={{ mt: "8px" }}>
          <strong>*Forecast Monthly :</strong>
        </Grid>
        <Grid item xs={2}>
          <InputMask
            id="input-apmc-month"
            mask="mM/YYYY"
            required
            fullWidth={true}
            formatChars={{
              Y: "[0-9]",
              m: "[0-1]",
              M: "[0-9]",
            }}
            maskChar={null}
            alwaysShowMask={false}
            value={form?.forecastMonth}
            onChange={e => {
              setForm(prev => ({
                ...prev,
                [API_NAME_APMC.FORECAST_MONTH]: e.target.value,
              }));
            }}
            disabled={[ModeAction.ADD, ModeAction.EDIT].includes(mode)}
            autoFocus={true}
          />
        </Grid>
        <Grid item xs={2} sx={{ mt: "8px" }}>
          <strong>(MM/YYYY)</strong>
        </Grid>
        <Grid item xs={2} sx={{ mt: "8px" }}>
          <strong>Mode :</strong>
        </Grid>
        <Grid item xs={3}>
          <DropDown
            id="input-apmc-mode"
            value={form?.mode}
            onChange={e => {
              setForm(prev => ({
                ...prev,
                [API_NAME_APMC.MODE]: e.target.value,
              }));
            }}
            menu={modeList?.map(val => ({
              key: val?.value,
              value: val?.value,
            }))}
            disabled={[ModeAction.ADD, ModeAction.EDIT].includes(mode)}
            defaultValue=""
            placeholder="<All>"
          />
        </Grid>
        <Grid item xs={2} sx={{ mt: "8px" }}>
          <strong>Data Type : </strong>
        </Grid>
        <Grid item xs={3}>
          <DropDown
            id="select-apmc-data-type"
            value={form?.dataTypeCd}
            onChange={e => {
              setForm(prev => ({
                ...prev,
                [API_NAME_APMC.DATA_TYPE_CD]: e.target.value,
              }));
            }}
            menu={dataTypeList?.map(val => ({
              key: val.cd,
              value: val.value,
            }))}
            defaultValue=""
            disabled={[ModeAction.ADD, ModeAction.EDIT].includes(mode)}
            placeholder="<All>"
          />
        </Grid>
        <Grid item xs={1}></Grid>
        <Grid item xs={2} sx={{ mt: "8px" }}>
          <strong>Upload status : </strong>
        </Grid>
        <Grid item xs={3}>
          <DropDown
            id="select-apmc-upload-status"
            value={form?.uploadStatusCd}
            onChange={e => {
              setForm(prev => ({
                ...prev,
                [API_NAME_APMC.UPLOAD_STATUS_CD]: e.target.value,
              }));
            }}
            menu={uploadStatusList?.map(val => ({
              key: val.cd,
              value: val.value,
            }))}
            disabled={[ModeAction.ADD, ModeAction.EDIT].includes(mode)}
            placeholder="<All>"
            defaultValue=""
          />
        </Grid>
        <Grid item xs={2} sx={{ mt: "8px" }}>
          <strong>Plan Template : </strong>
        </Grid>
        <Grid item xs={8} sx={{ mt: "8px" }}>
          <TemplateBar setMsg={setMsg} />
        </Grid>
      </Grid>
    </Fragment>
  );
}
