import { AuthenticatedTemplate, useMsal, UnauthenticatedTemplate, useMsalAuthentication } from "@azure/msal-react";
import { InteractionType } from "@azure/msal-browser";
import { Profile } from "components/Layout/Profile";

export const MainContent = ({ children }) => {
  useMsalAuthentication(InteractionType.Redirect);
  const { instance } = useMsal();
  // console.log("instance >> ", instance);

  const activeAccount = instance.getActiveAccount();
  console.log("activeAccount", activeAccount);
  console.log(`Authentication Success with...${activeAccount?.username}`);
  return (
    <>
      <AuthenticatedTemplate>
        <Profile />
        {children}
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        {/* <h5 className="card-title">Please sign-in to see your profile information.</h5> */}
      </UnauthenticatedTemplate>
    </>
  );
};
