import { Box, Grid } from "@mui/material";
import React, { Fragment, useEffect, useMemo, useState } from "react";
import {
  InputText,
  InputDropDown,
  InputEditTableCheckbox,
  InputEditTableDropDown,
  InputEditTableText,
} from "components/UI/index";
import {
  apiClient,
  message,
  responseErrors,
  findObject,
  messageTypeDisplay,
  responseDownloadFileErrors,
} from "utils";
import { HeaderContentBar } from "components/Layout";
import { AlertMessage } from "components/UI";
import ActionBar from "../Partials/ActionBar";
import { MessageType, ModeAction } from "state/enum";
import { useConfirmDialogContext } from "context/confirmDialogContext";
import SubmitBar from "pages/Partials/SubmitBar";
import DataTable from "pages/DataTable";
import { GridRowModes } from "@mui/x-data-grid";

import { columEditTable } from "utils/columEditTable";
import {
  useGetCountry,
  useGetCountryMaster,
  useGetRoutePointForm,
  useGetRoutePointInTable,
  useGetRoutePointTo,
  useSearchPathMaster,
  usePathMasterDownloadExcelMutation,
} from "service/pathmaster";
import { useGridApiContext } from "@mui/x-data-grid";
import { userProfile } from "constant";
import {
  validationRequestErrors,
  validationSearchForm,
} from "utils/validation";
import { getLocalDate } from "utils/init-config-date";

const PathMasterScreen = () => {
  const confirmDialogCtx = useConfirmDialogContext();

  const [mode, setMode] = useState("view");
  const [rows, setRows] = useState([]);
  const [rowModesModel, setRowModesModel] = useState({});

  const [rowSelectionModel, setRowSelectionModel] = useState([]);

  const [onSearch, setOnSearch] = useState(false);

  const [msgError, setMsgError] = useState([]);
  const [msgAlert, setMsgAlert] = useState([]);

  useEffect(() => {
    document.getElementById("input_pathName").focus();
  }, []);

  const [searchForm, setSearchForm] = useState({
    pathName: "",
    countryFrom: "",
    countryTo: "",
    routePointFrom: "",
    routePointTo: "",
  });

  const [countryFromInTableCd, setCountryFromInTableCd] = useState("");
  const [countryToInTableCd, setCountryToInTableCd] = useState("");

  // 1. pagination
  const [pagination, setPagination] = useState({});
  const [pageNumber, setPageNumber] = useState(1);
  const handleChangePagination = async (event, value) => {
    setPageNumber(value);

    const { searchData, data } = await getSearch(value);

    setPagination(searchData?.result?.pagination ?? {});
    setPageNumber(searchData?.result?.pagination?.pageNumber);

    setRows(data);
    // const searchData = await searchDataAsync({
    //   dataOwner: userProfile.dataOwner,
    //   pathName: searchForm.pathName,
    //   fromRoutePointId: searchForm.routePointFrom,
    //   toRoutePointId: searchForm.routePointTo,
    //   fromCountryCd: searchForm.countryFrom,
    //   toCountryCd: searchForm.countryTo,
    //   pageNumber: value,
    //   rowsPerPage: 10,
    // });

    // const data =
    //   searchData?.result?.data?.map((item, index) => ({
    //     rowNumber: index + 1,
    //     ...item,
    //   })) ?? [];
  };

  // 2. api
  const downloadExcel = usePathMasterDownloadExcelMutation();
  const { data: countryData } = useGetCountryMaster({
    category: "COMMON",
    subCategory: "COUNTRY_CD",
    dataOwner: userProfile.dataOwner,
  });

  const { data: countryFromData } = useGetCountry({
    dataOwner: userProfile.dataOwner,
    countryCd: "FROM_COUNTRY_CD",
  });

  const { data: countryToData } = useGetCountry({
    dataOwner: userProfile.dataOwner,
    countryCd: "TO_COUNTRY_CD",
  });

  const { data: routePointFormData } = useGetRoutePointForm({
    dataOwner: userProfile.dataOwner,
    countryCd: searchForm?.countryFrom,
  });

  const { data: routePointToData } = useGetRoutePointTo({
    dataOwner: userProfile.dataOwner,
    countryCd: searchForm?.countryTo,
  });

  const { data: routePointFormInTableData } = useGetRoutePointInTable({
    dataOwner: userProfile.dataOwner,
    countryCd: countryFromInTableCd,
  });

  const { data: routePointToInTableData } = useGetRoutePointInTable({
    dataOwner: userProfile.dataOwner,
    countryCd: countryToInTableCd,
  });

  const { mutateAsync: searchDataAsync } = useSearchPathMaster({});

  // 3. initail value search from
  //

  // 4. columns
  const columns = useMemo(
    () => [
      {
        field: "rowNumber",
        align: "right",
        sortable: false,
        headerName: "No",
        width: 50,
        editable: false,
        renderHeader: () => <div></div>,
        renderCell: (params) => {
          if (
            params.row.rowNumber === rows.slice(-1)?.[0].rowNumber &&
            ModeAction.ADD === mode
          ) {
            return <div></div>;
          }
          // return params.value;
          return params.value;
        },
      },
      {
        field: "pathName",
        sortable: false,
        headerName: "Path Name",
        renderHeader: () => <div></div>,
        align: "left",
        editable: true,
        minWidth: 300,
        type: "text",
        rule: [
          {
            type: MessageType.EMPTY,
            key: ["Path Name"],
          },
        ],
        renderEditCell: (params) => {
          return (
            <InputEditTableText
              autoFocus
              {...params}
              required
              style={{ width: "100%" }}
              regularExp={/^[a-zA-Z0-9\s/_]*$/}
              maxLength={40}
            />
          );
        },
        flex: 3,
      },
      // columEditTable({
      //   field: "pathName",
      //   align: "left",
      //   editable: true,
      //   width: 350,
      //   type: "text",
      //   required: true,
      //   rule: [
      //     {
      //       type: MessageType.EMPTY,
      //       key: ["Path Name"],
      //     },
      //   ],
      // }),
      {
        field: "fromCountryCd",
        headerName: "Country",
        sortable: false,
        editable: ModeAction.ADD === mode,
        minWidth: mode === ModeAction.ADD ? 140 : 100,
        headerAlign: "center",
        rule: [
          {
            type: MessageType.EMPTY,
            key: ["Country (From)"],
          },
        ],
        renderEditCell: (params) => {
          if (params.value !== "") {
            setCountryFromInTableCd(params.value);
          }

          return (
            <InputEditTableDropDown
              {...params}
              required
              memu={countryData?.map((val) => ({
                key: val.cd,
                value: val.value,
              }))}
              placeholder="<Select>"
            />
          );
        },
        renderCell: (params) => {
          return findObject({
            data: countryFromData,
            value: params.value,
            property: "cd",
            field: "value",
          });
        },
        flex: 1,
      },
      {
        field: "fromRoutePointId",
        headerName: "Route Point",
        sortable: false,
        editable: ModeAction.ADD === mode,
        minWidth: 150,
        headerAlign: "center",
        rule: [
          {
            type: MessageType.EMPTY,
            key: ["Route Point (From)"],
          },
        ],
        renderEditCell: (params) => {
          let value =
            params.value !== ""
              ? routePointFormInTableData?.find(
                  (val) => val.routePointId === params.value
                )?.routePointName
              : "";

          let toRoutePointName =
            params.row.toRoutePointId !== ""
              ? routePointToInTableData?.find(
                  (val) => val.routePointId === params.row.toRoutePointId
                )?.routePointName
              : "";

          // eslint-disable-next-line react-hooks/rules-of-hooks
          const apiRef = useGridApiContext();

          apiRef.current.setEditCellValue({
            id: params.id,
            field: "pathName",
            value:
              value !== "" ? value + "_" + toRoutePointName : toRoutePointName,
          });

          return (
            <InputEditTableDropDown
              {...params}
              required
              memu={routePointFormInTableData?.map((val) => ({
                key: val.routePointId,
                value: val.routePointName,
              }))}
              placeholder="<Select>"
            />
          );
        },
        renderCell: (params) => {
          return params.row.fromRoutePointName;
        },
        flex: 2,
      },
      {
        field: "toCountryCd",
        headerName: "Country",
        sortable: false,
        editable: ModeAction.ADD === mode,
        minWidth: mode === ModeAction.ADD ? 140 : 100,
        headerAlign: "center",
        rule: [
          {
            type: MessageType.EMPTY,
            key: ["Country (To)"],
          },
        ],
        renderEditCell: (params) => {
          if (params.value !== "") {
            setCountryToInTableCd(params.value);
          }

          return (
            <InputEditTableDropDown
              {...params}
              required
              memu={countryData?.map((val) => ({
                key: val.cd,
                value: val.value,
              }))}
              placeholder="<Select>"
            />
          );
        },
        renderCell: (params) => {
          return params.row.toCountryName;
        },
        flex: 1,
      },
      {
        field: "toRoutePointId",
        headerName: "Route Point",
        sortable: false,
        editable: ModeAction.ADD === mode,
        minWidth: 150,
        headerAlign: "center",
        rule: [
          {
            type: MessageType.EMPTY,
            key: ["Route Point (To)"],
          },
        ],
        renderEditCell: (params) => {
          let fromRoutePointName =
            params.row.fromRoutePointId !== ""
              ? routePointFormInTableData?.find(
                  (val) => val.routePointId === params.row.fromRoutePointId
                )?.routePointName
              : "";

          let value =
            params.value !== ""
              ? routePointToInTableData?.find(
                  (val) => val.routePointId === params.value
                )?.routePointName
              : "";

          // eslint-disable-next-line react-hooks/rules-of-hooks
          const apiRef = useGridApiContext();

          apiRef.current.setEditCellValue({
            id: params.id,
            field: "pathName",
            value:
              fromRoutePointName !== ""
                ? fromRoutePointName + "_" + value
                : value,
          });

          return (
            <InputEditTableDropDown
              {...params}
              required
              memu={routePointToInTableData?.map((val) => ({
                key: val.routePointId,
                value: val.routePointName,
              }))}
              placeholder="<Select>"
            />
          );
        },
        renderCell: (params) => {
          return params.row.toRoutePointName;
        },
        flex: 2,
      },
      {
        field: "enableFlag",
        sortable: false,
        headerName: "Enable Flag",
        headerAlign: "center",
        editable: true,
        minWidth: 100,
        align: "center",
        renderHeader: () => <></>,
        renderEditCell: (params) => {
          if (ModeAction.ADD === mode) {
            console.log("paramsparamsparamsparams ===", params.value);
            if (params.value !== "") {
              return (
                <InputEditTableCheckbox
                  {...params}
                  checked={params.value === "Y"}
                  value={params.value}
                />
              );
            } else {
              return (
                <InputEditTableCheckbox
                  {...params}
                  checked={true}
                  value={"Y"}
                />
              );
            }
          }
          return (
            <InputEditTableCheckbox
              {...params}
              checked={params.value === "Y"}
              // checked
            />
          );
        },
        renderCell: (params) => {
          return (
            <InputEditTableCheckbox
              {...params}
              checked={params.value === "Y"}
              disabled
              color="secondary"
            />
          );
        },
        flex: 1,
      },
      // columEditTable({
      //   field: "description",
      //   align: "left",
      //   editable: true,
      //   width: 450,
      //   type: "text",
      // }),
      {
        field: "description",
        sortable: false,
        headerName: "Description",
        renderHeader: () => <div></div>,
        align: "left",
        editable: true,
        minWidth: 170,
        type: "text",
        renderEditCell: (params) => {
          return (
            <InputEditTableText
              {...params}
              style={{ width: "100%" }}
              maxLength={100}
              regularExp={/^.*$/}
            />
          );
        },
        flex: 3,
      },
    ],
    [rows, routePointFormInTableData, routePointToInTableData]
  );

  // 5. columnGroupingModel
  const columnGroupingModel = [
    {
      groupId: "No.",
      headerAlign: "center",
      children: [{ field: "rowNumber" }],
      headerClassName: "align-items-end",
    },
    {
      groupId: "Path Name",
      headerAlign: "center",
      children: [{ field: "pathName" }],
      headerClassName: "align-items-end",
    },
    {
      groupId: "From",
      headerAlign: "center",
      children: [{ field: "fromCountryCd" }, { field: "fromRoutePointId" }],
      headerClassName: "table-header-group",
    },
    {
      groupId: "To",
      headerAlign: "center",
      children: [{ field: "toCountryCd" }, { field: "toRoutePointId" }],
      headerClassName: "table-header-group",
    },
    {
      groupId: "Enable Flag",
      headerAlign: "center",
      children: [{ field: "enableFlag" }],
      headerClassName: "align-items-end",
    },
    {
      groupId: "Description",
      headerAlign: "center",
      children: [{ field: "description" }],
      headerClassName: "align-items-end",
    },
  ];

  const getSearch = async (pageNumber = 1) => {
    setOnSearch(true);
    const searchData = await searchDataAsync({
      dataOwner: userProfile.dataOwner,
      pathName: searchForm.pathName,
      fromRoutePointId: searchForm.routePointFrom,
      toRoutePointId: searchForm.routePointTo,
      fromCountryCd: searchForm.countryFrom,
      toCountryCd: searchForm.countryTo,
      pageNumber: pageNumber,
      rowsPerPage: 10,
    });

    const data =
      searchData?.result?.data?.map((item, index) => ({
        rowNumber: index + 1,
        ...item,
      })) ?? [];

    return { searchData, data };
  };

  // 6. handleSearch
  const handleSearch = async (e) => {
    try {
      e.preventDefault();
      setRows([]);
      setMsgError([]);
      setMsgAlert([]);
      setRowSelectionModel([]);
      setMode(ModeAction.VIEW);

      // validate
      let stopProcess = false;
      if (searchForm.pathName.charAt(0) === "*") {
        const msg = messageTypeDisplay(MessageType.INVALID, ["Path"]);
        setMsgError((old) => [...old, msg]);
        stopProcess = true;
      }

      if (stopProcess) {
        window.scrollTo(0, 0);
        return;
      }

      const { searchData, data } = await getSearch();
      if (!data.length) {
        setMsgError([message({ type: "notFound" })]);
      }
      // const { isSuccess, errors } = validationSearchForm({
      //   rule: [
      //     {
      //       data: data,
      //       type: MessageType.NOT_FOUND,
      //     },
      //   ],
      // });

      // if (!isSuccess) {
      //   setMsgError(errors);
      //   window.scrollTo(0, 0);
      //   return;
      // }

      setPagination(searchData?.result?.pagination ?? {});
      setPageNumber(searchData?.result?.pagination?.pageNumber);
      setRows(data);
    } catch (error) {
      const errors = responseErrors(error);
      setMsgError(errors);
      window.scrollTo(0, 0);
      return;
    }
  };

  // 7. handleDeleteClick
  const handleDeleteClick = async () => {
    setMsgError([]);
    setMsgAlert([]);
    const confirm = await confirmDialogCtx.success({ type: "confirmDelete" });
    if (!confirm) {
      return;
    }
    try {
      const selectedNo = rowSelectionModel[0];
      const id = rows.find((v) => v.rowNumber === selectedNo)?.pathId;

      await apiClient.delete(`/path/delete/${id}`, {
        data: { updateBy: userProfile.userId },
      });

      const { searchData, data } = await getSearch(pageNumber);

      setPagination(searchData?.result?.pagination ?? {});
      setPageNumber(searchData?.result?.pagination?.pageNumber);
      setRows(data);

      setRowSelectionModel([]);
      setMode(ModeAction.VIEW);

      const msg = messageTypeDisplay(MessageType.DELETED);
      setMsgAlert([msg]);
      window.scrollTo(0, 0);
    } catch (error) {
      const errors = responseErrors(error);
      setMsgError(errors);
      return;
    }
  };

  // 8. processRowUpdate (Action Add and Edit)
  const processRowUpdate = async (newRow) => {
    setMsgError([]);
    setMsgAlert([]);
    console.log("+++newRow", newRow);
    console.log("-routePointFormInTableData", routePointFormInTableData);

    const { isSuccess, errors } = validationRequestErrors({
      columns: columns,
      data: newRow,
    });

    if (!isSuccess) {
      setMsgError(errors);
      window.scrollTo(0, 0);
      return;
    }

    if (newRow.isNew) {
      try {
        await apiClient.post(`/path/create`, {
          dataOwner: userProfile.dataOwner,
          pathName: newRow.pathName,
          fromCountryCd: newRow.fromCountryCd,
          fromRoutePointId: newRow.fromRoutePointId,
          fromRoutePointName: findObject({
            data: routePointFormInTableData,
            value: newRow.fromRoutePointId,
            property: "routePointId",
            field: "routePointName",
          }),
          toCountryCd: newRow.toCountryCd,
          toRoutePointId: newRow.toRoutePointId,
          toRoutePointName: findObject({
            data: routePointToInTableData,
            value: newRow.toRoutePointId,
            property: "routePointId",
            field: "routePointName",
          }),
          enableFlag:
            newRow.enableFlag === "" || newRow.enableFlag === "Y"
              ? "Y"
              : newRow.enableFlag,
          description: newRow.description,
          refFileUploadId: "",
          createBy: userProfile.userId, // TODO: get dataOwner from profile
        });

        const msg = messageTypeDisplay(MessageType.ADDED);
        setMsgAlert([msg]);
        window.scrollTo(0, 0);
      } catch (error) {
        const errors = responseErrors(error);
        setMsgError(errors);
        window.scrollTo(0, 0);
        return;
      }
    } else {
      try {
        await apiClient.patch(`/path/edit/${newRow.pathId}`, {
          pathName: newRow.pathName,
          // enableFlag: newRow.enableFlag,
          enableFlag:
            newRow.enableFlag === "" || newRow.enableFlag === "Y"
              ? "Y"
              : newRow.enableFlag,
          description: newRow.description,
          updateBy: userProfile.userId, // TODO: get dataOwner from profile
        });

        const msg = messageTypeDisplay(MessageType.UPDATED);
        setMsgAlert([msg]);
        window.scrollTo(0, 0);
      } catch (error) {
        const errors = responseErrors(error);
        setMsgError(errors);
        window.scrollTo(0, 0);
        return;
      }
    }

    if (rows.length === 1 && ModeAction.ADD === mode) {
      setMode(ModeAction.VIEW);
      setRowSelectionModel([]);
      setRows([]);
    } else {
      const { searchData, data } = await getSearch(pageNumber);

      setMode(ModeAction.VIEW);
      setRowSelectionModel([]);

      setPagination(searchData?.result?.pagination ?? {});
      setPageNumber(searchData?.result?.pagination?.pageNumber);
      setRows(data);
    }

    const updatedRow = { ...newRow, isNew: false };
    return updatedRow;
  };

  // 9. handleClear
  const handleClear = () => {
    setMode(ModeAction.VIEW);
    setMsgError([]);
    setMsgAlert([]);
    setRowSelectionModel([]);
    setOnSearch(false);
    setSearchForm({
      pathName: "",
      countryFrom: "",
      countryTo: "",
      routePointFrom: "",
      routePointTo: "",
    });
    setRows([]);
    setPagination({});
    setPageNumber(1);
  };

  // 10. handleDownloadExcel
  const handleDownloadExcel = async () => {
    // validate
    setMsgError([]);
    setMsgAlert([]);
    try {
      const { data } = await getSearch();
      if (!data.length) {
        const msg = messageTypeDisplay(MessageType.NOT_FOUND);
        setMsgError((old) => [...old, msg]);
        return;
      }
    } catch (error) {
      const errors = responseErrors(error);
      setMsgError(errors);
      window.scrollTo(0, 0);
      return;
    }
    try {
      const option = {
        dataOwner: userProfile.dataOwner,
        pathName: searchForm.pathName,
        fromCountryCd: searchForm.countryFrom,
        toCountryCd: searchForm.countryTo,
        fromRoutePointId: searchForm.routePointFrom,
        toRoutePointId: searchForm.routePointTo,
        pageNumber: pageNumber,
        rowsPerPage: 10,
      };
      // const filename = `Path_Master_${getLocalDate().format("YYYYMMDDHHmm")}.xlsx`;

      await downloadExcel(option);
    } catch (error) {
      const errors = responseDownloadFileErrors(error);
      setMsgError(errors);
      window.scrollTo(0, 0);
      return;
    }
  };

  const handleCopyClick = () => {
    setMode(ModeAction.ADD);
    setMsgError([]);
    setMsgAlert([]);
    setOnSearch(true);

    const rowCopy = rows.find((v) => v.rowNumber === rowSelectionModel[0]);
    console.log("rowCopy", rowCopy);
    const maxNo =
      !rows || rows.length === 0
        ? 1
        : Math.max(...rows.map((item) => item.rowNumber));

    setRows((oldRows) => [
      ...oldRows,
      {
        rowNumber: maxNo + 1,
        dataOwner: rowCopy.dataOwner,
        pathId: rowCopy.pathId,
        pathName: rowCopy.pathName,
        fromCountryCd: rowCopy.fromCountryCd,
        fromRoutePointId: rowCopy.fromRoutePointId,
        fromRoutePointName: rowCopy.fromRoutePointName,
        toCountryCd: rowCopy.toCountryCd,
        toRoutePointId: rowCopy.toRoutePointId,
        toRoutePointName: rowCopy.toRoutePointName,
        description: rowCopy.description,
        transportCategory: rowCopy.transportCategory,
        enableFlag: rowCopy.enableFlag,
        deleteFlag: rowCopy.deleteFlag,
        refFileUploadId: rowCopy.refFileUploadId,
        createDt: rowCopy.createDt,
        createBy: rowCopy.createBy,
        updateDt: rowCopy.updateDt,
        updateBy: rowCopy.updateBy,
        fromCountryName: rowCopy.fromCountryName,
        toCountryName: rowCopy.toCountryName,
        isNew: true,
      },
    ]);

    setRowModesModel((oldModel) => ({
      ...oldModel,
      [maxNo + 1]: { mode: GridRowModes.Edit },
    }));

    setRowSelectionModel([maxNo + 1]);
  };

  return (
    <Fragment>
      <Box sx={{ padding: "1rem" }}>
        {/* Header */}
        <HeaderContentBar title="WDN91040 : Path Master Maintenance Screen" />
        {/* Alert Message */}
        <AlertMessage type={"warning"} message={msgError} />
        <AlertMessage type={"success"} message={msgAlert} />
        <Grid container spacing={0} sx={{ mt: 4, alignItems: "center" }}>
          <Grid item xs={2}>
            <strong>Path Name : </strong>
          </Grid>
          <Grid item xs={2}>
            <InputText
              // focused
              id="input_pathName"
              fullWidth={true}
              value={searchForm.pathName}
              regularExp={/^[a-zA-Z0-9\s/_]*$/}
              onChange={(e) =>
                setSearchForm((old) => ({
                  ...old,
                  pathName: e.target.value,
                }))
              }
              disabled={[ModeAction.ADD, ModeAction.EDIT].includes(mode)}
              maxLength={40}
            />
          </Grid>
          <Grid item xs={8}></Grid>

          <Grid item xs={2} sx={{ mt: 2 }}>
            <strong>Country (From) : </strong>
          </Grid>
          <Grid item xs={2} sx={{ mt: 2 }}>
            <InputDropDown
              id="select_countryFrom"
              value={searchForm.countryFrom}
              onChange={(e) =>
                setSearchForm((old) => ({
                  ...old,
                  countryFrom: e.target.value,
                }))
              }
              memu={countryFromData?.map((val) => ({
                key: val.cd,
                value: val.value,
              }))}
              disabled={[ModeAction.ADD, ModeAction.EDIT].includes(mode)}
              placeholder="<All>"
              defaultValue=""
            />
          </Grid>
          <Grid item xs={1}></Grid>

          <Grid item xs={2} sx={{ mt: 2 }}>
            <strong>Route Point (From) : </strong>
          </Grid>
          <Grid item xs={3} sx={{ mt: 1, ml: 3 }}>
            <InputDropDown
              id="select_routePointFrom"
              value={searchForm.routePointFrom}
              onChange={(e) =>
                setSearchForm((old) => ({
                  ...old,
                  routePointFrom: e.target.value,
                }))
              }
              memu={routePointFormData?.map((val) => ({
                key: val.fromRoutePointId,
                value: val.fromRoutePointName,
              }))}
              disabled={[ModeAction.ADD, ModeAction.EDIT].includes(mode)}
              placeholder="<All>"
              defaultValue=""
            />
          </Grid>

          <Grid item xs={2} sx={{ mt: 2 }}>
            <strong>Country (To) : </strong>
          </Grid>
          <Grid item xs={2} sx={{ mt: 2 }}>
            <InputDropDown
              id="select_countryTo"
              value={searchForm.countryTo}
              onChange={(e) =>
                setSearchForm((old) => ({
                  ...old,
                  countryTo: e.target.value,
                }))
              }
              memu={countryToData?.map((val) => ({
                key: val.cd,
                value: val.value,
              }))}
              disabled={[ModeAction.ADD, ModeAction.EDIT].includes(mode)}
              placeholder="<All>"
              defaultValue=""
            />
          </Grid>
          <Grid item xs={1}></Grid>

          <Grid item xs={2} sx={{ mt: 2 }}>
            <strong>Route Point (To) : </strong>
          </Grid>
          <Grid item xs={3} sx={{ mt: 1, ml: 3 }}>
            <InputDropDown
              id="select_routePointTo"
              value={searchForm.routePointTo}
              onChange={(e) =>
                setSearchForm((old) => ({
                  ...old,
                  routePointTo: e.target.value,
                }))
              }
              memu={routePointToData?.map((val) => ({
                key: val.toRoutePointId,
                value: val.toRoutePointName,
              }))}
              disabled={[ModeAction.ADD, ModeAction.EDIT].includes(mode)}
              placeholder="<All>"
              defaultValue=""
            />
          </Grid>
        </Grid>
      </Box>
      <ActionBar
        mode={mode}
        rows={rows}
        columns={columns}
        setRows={setRows}
        setMode={setMode}
        setMsgError={setMsgError}
        setMsgAlert={setMsgAlert}
        setOnSearch={setOnSearch}
        setRowModesModel={setRowModesModel}
        setRowSelectionModel={setRowSelectionModel}
        rowSelectionModel={rowSelectionModel}
        rowModesModel={rowModesModel}
        handleSearch={handleSearch}
        handleClear={handleClear}
        handleDownloadExcel={handleDownloadExcel}
        handleDeleteClick={handleDeleteClick}
        handleCopyClick={handleCopyClick}
        firstField={"input_pathName"}
        functionId={"WDN91040"}
      />
      <Box sx={{ padding: "1rem" }}>
        <Grid>
          {onSearch && rows?.length !== 0 && (
            <>
              <DataTable
                mode={mode}
                onSearch={onSearch}
                rowSelectionModel={rowSelectionModel}
                setRowSelectionModel={setRowSelectionModel}
                setMode={setMode}
                rows={rows}
                rowModesModel={rowModesModel}
                columns={columns}
                setRowModesModel={setRowModesModel}
                processRowUpdate={processRowUpdate}
                pagination={pagination}
                pageNumber={pageNumber}
                handleChangePagination={handleChangePagination}
                columnGroupingModel={columnGroupingModel}
                columnVisibilityModel={{
                  currencys: false,
                  rPkgTypeId: false,
                }}
              />
            </>
          )}
          <SubmitBar
            mode={mode}
            rows={rows}
            setMode={setMode}
            setRows={setRows}
            setRowModesModel={setRowModesModel}
            setMsgError={setMsgError}
            rowModesModel={rowModesModel}
            rowSelectionModel={rowSelectionModel}
            setRowSelectionModel={setRowSelectionModel}
            functionId={"WDN91040"}
          />
        </Grid>
      </Box>
    </Fragment>
  );
};

export default PathMasterScreen;
