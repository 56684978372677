import { useCustomQuery } from "shared/services/base.service";
const BaseServicePath = process.env.REACT_APP_API_PREFIX;
const LogCategoryApiService = () => ({
  // GET LOG STATUS
  useLogMonitorGetLogStatusQuery: () => {
    const data = useCustomQuery({
      url: `${BaseServicePath}/category/COMMON/LOG_STATUS/ASC`,
      method: "GET",
      enabled: true,
      select: data => data.result,
      keepPreviousData: true,
    });
    return data;
  },
  // GET LOG DETAIL
  useLogMonitorGetLogStatusDetailQuery: () => {
    const data = useCustomQuery({
      url: `${BaseServicePath}/category/COMMON/LOG_STATUS_DETAIL/ASC`,
      method: "GET",
      enabled: true,
      select: data => data.result,
      keepPreviousData: true,
    });
    return data;
  },
});
export const { useLogMonitorGetLogStatusDetailQuery, useLogMonitorGetLogStatusQuery } = LogCategoryApiService();
